import {requestService} from "../Helpers/RequestService";
import {ApiRoutes} from "../../ApiRoutes";
import {OgonePropertiesDTO} from "../../Data/OgonePropertiesDTO";

async function getGeneratedSHA1Key(key:String): Promise<string> {

    const sha1KeyRequestDTO = {
        key: key
    }

    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_OGONE, sha1KeyRequestDTO)
        .then((response) => {
            return response.data as string;
        });

}

async function getOgoneProperties(): Promise<OgonePropertiesDTO> {

        return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_OGONE + '/properties')
            .then((response) => {
                return response.data as OgonePropertiesDTO;
            });
}

export const OgoneService = {
    getGeneratedSHA1Key,
    getOgoneProperties
}