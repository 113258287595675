export class Page<T> {
    content: T[];
    totalElements: number;
    totalPages: number;
    size: number;
    number: number;
    isLoaded: boolean;

    constructor(data?: any) {
        this.content = data?.content ?? [];
        this.totalElements = data?.totalElements ?? 0;
        this.totalPages = data?.totalPages ?? 0;
        this.size = data?.size ?? 0;
        this.number = data?.number ?? 0;
        this.isLoaded = data?.isLoaded ?? true;
    }
}