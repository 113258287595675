import {useTranslation} from "react-i18next";
import Select from "react-select";
import {SelectOptionView} from "../../../Data/view/SelectOption/SelectOptionView";
import {CPlaceholder} from "@coreui/react";
import React from "react";


interface BbxMultiSelect {
    options: SelectOptionView[]
    value: string | null
    onChange: (value: string | null) => void
    placeholder: string
    noOptionsMessage?: string
    translateLabel?: boolean
    sortOptions?: boolean
    required?: boolean
}


export function BbxSearchSelect({
                                    options,
                                    value,
                                    onChange,
                                    placeholder,
                                    noOptionsMessage,
                                    translateLabel,
                                    sortOptions,
                                    required
                                }: BbxMultiSelect) {
    const {t} = useTranslation();


    const convertOptions = () => {
        let translatedOptions = options;

        if (translateLabel === true) {
            translatedOptions = options.map((option) => {
                return {
                    ...option,
                    label: t(option.label),
                };
            });
        }

        if (sortOptions === false) {
            return translatedOptions;
        }

        return translatedOptions.sort((a, b) => a.label.localeCompare(b.label));
    };

    if (options.length == 0) {
        return (
            <div>
                <CPlaceholder animation={"glow"}>
                    <CPlaceholder xs={12} size={"lg"}/>
                </CPlaceholder>
            </div>
        )
    }

    return (
        <Select
            // className={"form-select border-blue p-0"}
            styles={{
                control: (provided: any, state: any) => ({
                    ...provided,
                    border: state.isFocused ? "2px solid var(--light-blue) !important" : '2px solid var(--border-blue) !important',
                    borderRadius: '0rem',
                    padding: '0rem',
                    boxShadow: state.isFocused ? 'none' : 'none',
                }),
                menu: (provided: any) => ({
                    ...provided,
                    zIndex: 9999,
                }),
            } as any}

            options={convertOptions()}
            value={convertOptions().filter((option) => value === option.value)}
            onChange={(selectedOptions) => {
                onChange(selectedOptions?.value ?? null);
            }}
            isClearable={true}
            className={"w-100"}
            placeholder={placeholder}
            noOptionsMessage={() => t(noOptionsMessage ?? t('No results'))}
            required={required}
        />
    );
}