import {BbxRequest} from "../Data/request/BbxRequest";

export default class RequestUtil {


    static reset<T>(request: BbxRequest<T>) {
        request.page = 0;
        request.data = request.initialState;
        request.searchForm = request.initialState;

        return request;
    }

    static newSearch<T>(request: BbxRequest<T>) {
        request.page = 0;
        request.data = request.searchForm;

        return request;
    }

    static switchPage<T>(request: BbxRequest<T>, page: number) {
        request.page = page;
        request.searchForm = request.data;

        return request;

    }


    static switchPageSize<T>(request: BbxRequest<T>, size: number) {
        request.size = size;
        request.page = 0;
        request.searchForm = request.data;

        return request;

    }

    static switchSortDirection<T>(request: BbxRequest<T>, sortDirection: string) {
        request.sortDirection = sortDirection;
        request.page = 0;
        request.searchForm = request.data;

        return request;
    }

    static switchSortColumn<T>(request: BbxRequest<T>, sortColumn: string) {
        request.sortColumn = sortColumn;
        request.page = 0;
        request.searchForm = request.data;

        return request;
    }

    static prepareRequest<T>(request: BbxRequest<T>) {
        return {
            page: request.page,
            size: request.size,
            sortColumn: request.sortColumn,
            sortDirection: request.sortDirection,
            data: request.data,
        }
    }
}