//Used for props type in SupplierContact.tsx
import {CompanyView} from "../CompanyModel/CompanyView";
import {FileView} from "../FileModel/FileView";

export class MemberView {

    id: number;
    firstName: string;
    lastName: string;
    email: string;
    company: CompanyView;
    portFolio: FileView[];

    // contact: {
    //     name: string,
    //     mail: string,
    //     tel: string,
    // }

    constructor(data?: any) {
        this.id = data?.id ?? 0
        this.firstName = data?.firstName ?? ""
        this.lastName = data?.lastName ?? ""
        this.email = data?.email ?? ""
        this.company = data?.company ?? new CompanyView()
        this.portFolio = data?.portFolio ?? []
        // this.contact = data?.contact ?? {
        //     name: "",
        //     mail: "",
        //     tel: "",
        // }
    }


}