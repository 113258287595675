import React, {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {accountService} from "../../Services/Helpers/AccountService";
import {UserDataInterface} from "../../Shared/Interfaces/UserData.interface";
import i18n from "i18next";
import {RouteUtils} from "../../utils/RouteUtils";

export default function Bo() {

    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {

        accountService.boLogin(searchParams.get('token') ?? "").then(
            async result => {

                accountService.saveToken(result?.data?.access_token);
                accountService.saveRefreshToken(result?.data?.refresh_token);

                //Set User
                let user: UserDataInterface = {
                    loginId: "temp_admin_access",
                    authority: result?.data?.authority,
                    role: result?.data?.role,
                    locale: result?.data?.locale,
                    languageId: result?.data?.languageId,
                    userID: result?.data?.userID,
                    isTeamMember: result?.data?.isTeamMember,
                    companyName: result?.data?.companyName,
                    firstname: result?.data?.firstname,
                    lastname: result?.data?.lastname,
                    companyPictureUrl: result?.data?.companyPictureUrl,
                    context: result?.data?.context,
                    teamMemberRole: result?.data?.teamMemberRole
                }

                accountService.saveUser(user);

                await i18n.changeLanguage(user.locale);

                //Redirect
                let redirectUrl = localStorage.getItem('redirect_to');

                if (redirectUrl) {
                    localStorage.removeItem('redirect_to');
                    window.location.href = redirectUrl;
                    return;
                }

                RouteUtils.redirectToUserPage(user);
            }
        ).catch(error => {
            console.error("Error during login: ", error);
            navigate("/login");
        })

    }, []);


    return (
        <>
        </>
    )
}