import React, {createContext, useContext, useState, useEffect, Dispatch, SetStateAction, ReactNode} from 'react';
import {ExtranetFolderView} from "../../../Data/view/ExtranetFolderModel/ExtranetFolderView";
import {Page} from "../../../Data/Page";
import {useLocation} from "react-router-dom";
import {AccountManagerView} from "../../../Data/view/MemberModel/AccountManagerView";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";
import {BbxToast} from "../../../CustomHooks/ToastCustomComponentProps/BbxToast";

type FolderUpdateProviderProps = {
    children: ReactNode;
};

interface SidebarUpdateContextType {
    refreshFolders: () => void;
    refreshHeader: () => void;
    triggerRefresh: boolean;
    triggerHeaderRefresh: boolean;
    folderView: Page<ExtranetFolderView>; // Assuming ExtranetFolderView is a type you have defined elsewhere
    setFolderView: Dispatch<SetStateAction<Page<ExtranetFolderView>>>; // Corrected type here
    isPartnerRfpEmpty: boolean;
    setIsPartnerRfpEmpty: Dispatch<SetStateAction<boolean>>;
    setAccountManager: Dispatch<SetStateAction<AccountManagerView>>;
    accountManager: AccountManagerView;
    showToast: (type: BbxToastType, message: string) => void;
}

// Provide initial context values matching the type above
const SidebarUpdateContext = createContext<SidebarUpdateContextType>({
    refreshFolders: () => {
    },
    refreshHeader: () => {

    },
    triggerRefresh: false,
    triggerHeaderRefresh: false,
    folderView: new Page<ExtranetFolderView>(),
    setFolderView: () => {
    },
    isPartnerRfpEmpty: true,
    setIsPartnerRfpEmpty: () => {
    },
    setAccountManager: () => {

    },
    accountManager: new AccountManagerView(),
    showToast: () => {}
});

export const useTemplateUpdate = () => useContext(SidebarUpdateContext);

export const SidebarUpdateProvider: React.FC<FolderUpdateProviderProps> = ({children}) => {
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const [triggerHeaderRefresh, setTriggerHeaderRefresh] = useState(false);
    const [folderView, setFolderView] = useState<Page<ExtranetFolderView>>(new Page<ExtranetFolderView>()); // Use your ExtranetFolderView type here
    const [isPartnerRfpEmpty, setIsPartnerRfpEmpty] = useState(false);
    const [accountManager, setAccountManager] = useState<AccountManagerView>(new AccountManagerView());
    const location = useLocation();
    const [showToastState, setShowToastState] = useState(false);
    const [toastType, setToastType] = useState(BbxToastType.SUCCESS);
    const [toastMessage, setToastMessage] = useState('');

    useEffect(() => {
        // Trigger the folder refresh function when the location changes
        refreshFolders();
    }, [location]); // This effect will run when the location changes

    useEffect(() => {
        setTriggerRefresh(false);
    }, []);

    const refreshFolders = () => {
        setTriggerRefresh(prev => !prev);
    };

    const refreshHeader = () => {
        setTriggerHeaderRefresh(prev => !prev);
    }

    const showToast = (type: BbxToastType, message: string) => {
        setToastType(type);
        setToastMessage(message);
        setShowToastState(true);
    };

    return (
        <SidebarUpdateContext.Provider
            value={{
                refreshFolders,
                refreshHeader,
                triggerRefresh,
                triggerHeaderRefresh,
                folderView,
                setFolderView,
                isPartnerRfpEmpty,
                setIsPartnerRfpEmpty,
                setAccountManager,
                accountManager,
                showToast
            }}>
            {children}
            <BbxToast
                autohide={true}
                visible={showToastState}
                onClose={() => setShowToastState(false)}
                title={toastType === BbxToastType.SUCCESS ? 'Success' : 'Error'}
                body={toastMessage}
                type={toastType}
            />
        </SidebarUpdateContext.Provider>
    );
};
