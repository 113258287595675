import {UserDataInterface} from "../Shared/Interfaces/UserData.interface";
import {ApiRoutes} from "../ApiRoutes";

function isRouteExcluded(routePath: string): boolean {
    switch (routePath) {
        case '/supplier/achievements/testimonial':
            return true;
        case '/supplier/account/contact-details':
            return true;
        default:
            return false;
    }
}

function isRouteOnlyForAdminRole(routePath: string, role: number): boolean {

    if (role === 1) {
        return true;
    }

    if (role === 3) {
        switch (routePath) {
            case '/supplier/activity/categories-and-work-areas':
                return false;
            case '/supplier/data-management/api':
                return false;
            case '/supplier/data-management/integration':
                return false;
            case '/supplier/account/team-members':
                return false;
            case '/supplier/account/invoices':
                return false;
            case '/supplier/account/credit-notes':
                return false;
            case '/supplier/account/profile':
                return false;
            case '/supplier/account/contact-details':
                return false;
            case '/supplier/achievements/statistics-and-ratings':
                return false;
            case '/supplier/achievements/testimonial':
                return false;
            case '/supplier/achievements/graphics':
                return false;
            default:
                return true;
        }
    } else {
        return true;
    }
}

function redirectToUserPage(user: UserDataInterface | null): void {
    if (user?.authority === 'buyer') {
        window.location.href = ApiRoutes.REACT_APP_CONTEXT_URL_FRONTEND() + '/buyer/projects/overview';
    } else if (user?.authority === 'supplier') {
        window.location.href = (ApiRoutes.REACT_APP_CONTEXT_URL_FRONTEND() + '/supplier/activity/leads');
    } else if (user?.authority === 'affiliate') {
        window.location.href = (ApiRoutes.REACT_APP_CONTEXT_URL_FRONTEND() + '/affiliate/dashboard');
    }
}

function getUserRedirectPage(user: UserDataInterface | null): string | undefined {
    if (user?.authority === 'buyer') {
        return ApiRoutes.REACT_APP_CONTEXT_URL_FRONTEND() + '/buyer/projects/overview';
    } else if (user?.authority === 'supplier') {
        return ApiRoutes.REACT_APP_CONTEXT_URL_FRONTEND() + '/supplier/activity/leads';
    } else if (user?.authority === 'affiliate') {
        return ApiRoutes.REACT_APP_CONTEXT_URL_FRONTEND() + '/affiliate/dashboard';
    }
}

function redirectToHomePage(user: UserDataInterface | null) {
    if (user?.authority === 'buyer') {
        return '/buyer/projects/overview';
    } else if (user?.authority === 'supplier') {
        return '/supplier/activity/leads';
    } else if (user?.authority === 'affiliate') {
        return '/affiliate/dashboard';
    }
}

function redirectAfterLogin(page: string, user: UserDataInterface): void {

    const partBetweenFirstAndSecondSlash = page.split("/")[1];

    if (user.authority === partBetweenFirstAndSecondSlash) {
        window.location.href = ApiRoutes.REACT_APP_CONTEXT_URL_FRONTEND() + page;
    } else {
        redirectToUserPage(user);
    }

}

function getContext() {

    if (window.location.hostname.includes('localhost')) {
        if (window.location.pathname.includes('extranet-be')) {
            return 'be';
        } else if (window.location.pathname.includes('extranet-nl')) {
            return 'nl';
        } else if (window.location.pathname.includes('extranet-ci')) {
            return 'ci';
        } else if (window.location.pathname.includes('extranet-vbk')) {
            return 'vbk';
        }

        return null;

    } else if (window.location.hostname.includes('my009')) {
        if (window.location.hostname.includes('my009.bobex.be')) {
            return 'be';
        } else if (window.location.hostname.includes('my009.bobex.nl')) {
            return 'nl';
        } else if (window.location.hostname.includes('my009.conversion-impact.be')) {
            return 'ci';
        } else if (window.location.hostname.includes('my009.verbouwkosten.com')) {
            return 'vbk';
        }
    } else {
        if (window.location.hostname.includes('my.bobex.be')) {
            return 'be';
        } else if (window.location.hostname.includes('my.bobex.nl')) {
            return 'nl';
        } else if (window.location.hostname.includes('my.conversion-impact.be')) {
            return 'ci';
        } else if (window.location.hostname.includes('my.verbouwkosten.com')) {
            return 'vbk';
        }
    }

    return null;
}

function picturePath() {

    if (window.location.hostname.includes('my009')) {

        switch (RouteUtils.getContext()) {
            case 'be':
                return 'https://my009.bobex.be';
            case 'nl':
                return 'https://my009.bobex.nl';
            case 'ci':
                return 'https://my009.conversion-impact.be';
            case 'vbk':
                return 'https://my009.verbouwkosten.com';
            default:
                return 'https://my009.bobex.be';
        }
    }

    if (window.location.hostname.includes('localhost')) {
        return 'http://localhost';
    }

    if (window.location.hostname.includes('bobex.be')) {
        return 'https://my.bobex.be';
    }

    if (window.location.hostname.includes('bobex.nl')) {
        return 'https://my.bobex.nl';
    }

    if (window.location.hostname.includes('conversion-impact.be')) {
        return 'https://my.conversion-impact.be';
    }

    return 'https://www.bobex.be';
}

function csvFileName() {

    if (RouteUtils.getContext() === 'be') {
        return 'bobexbe_export';
    } else if (RouteUtils.getContext() === 'nl') {
        return 'bobexnl_export';
    }
}

function isRouteBlockedIfPartnerEmpty(routePath: string, isEmpty: boolean) {

    if (isEmpty) {
        switch (routePath) {
            case '/supplier/projects/overview':
                return false;
            case '/supplier/projects/new-project':
                return false;
            default:
                return true;
        }
    } else {
        return true;
    }
}

export const RouteUtils = {
    isRouteExcluded,
    isRouteOnlyForAdminRole,
    redirectToUserPage,
    getUserRedirectPage,
    getContext,
    picturePath,
    csvFileName,
    redirectAfterLogin,
    redirectToHomePage,
    isRouteBlockedIfPartnerEmpty
}