import {PageRequestDTO} from "./PageRequestDTO";

export class InvoiceSearchRequestDTO extends PageRequestDTO{

    id: string;
    invoiceRef: string;
    dateStart: Date | null;
    dateEnd: Date | null;

    constructor(data?: any) {
        super(data);
        this.id = data?.id ?? "";
        this.dateStart = data?.dateStart ?? "";
        this.dateEnd = data?.dateEnd ?? "";
        this.invoiceRef = data?.invoiceRef ?? "";
    }

}