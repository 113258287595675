import {PartnerRfpRequestDTO} from "./PartnerRfpRequestDTO";

export class PartnerSubmissionRequestDTO {
    id: number;
    partnerRfpRequest: PartnerRfpRequestDTO;
    requestSurvey: boolean;
    status: number[];
    statusNotIn: number[];
    folderId: number;

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.partnerRfpRequest = data?.partnerRfpRequest ?? new PartnerRfpRequestDTO();
        this.requestSurvey =  data?.requestSurvey ?? false;
        this.status = data?.status ?? [];
        this.statusNotIn = data?.statusNotIn ?? [];
        this.folderId = data?.folderId ?? null;

    }
}
