import {BbxRequest} from "../../Data/request/BbxRequest";
import {BannerAffiliateRequestDTO} from "../../Data/request/BannerAffiliateRequestDTO";

export default function BbxRequestReducer(state: BbxRequest<any>, action: any): BbxRequest<any> {

    switch (action.type) {
        case 'SET_PAGE':
            return {...state, page: action.payload};
        case 'SET_SIZE':
            return {...state, size: action.payload, page: 0};
        case 'SET_SORT_DIRECTION':
            return {...state, sortDirection: action.payload,page: 0};
        case 'SET_SORT_COLUMN':
            return {...state, sortColumn: action.payload,page: 0};
        case 'SET_DATA':
            return {...state, data: {...state.data, ...action.payload}};
        case 'SET_SEARCH_FORM':
            return {...state, searchForm: {...state.searchForm, ...action.payload}};
        case 'SET_REQUEST':
            return action.payload;
        case 'RESET':
            return new BbxRequest(BannerAffiliateRequestDTO); // Reset to initial state
        default:
            return state;
    }
}