import {RouteUtils} from "./RouteUtils";
import {accountService} from "../Services/Helpers/AccountService";

function formatDisplayLogo(picture: string) {

    if (picture === null || picture === undefined || picture === "") {
        return "";
    }

    const picturePath = RouteUtils.picturePath();

    const parts = picture.split("files");
    const formatedParts = parts[1].replace(/\\/g, "/");

    return picturePath + "/files" + formatedParts;
}

function displayBanner(userID: number) {


    const picturePath = RouteUtils.picturePath();

    return picturePath + "/files/" + userID + "/banner.png";
}

function getUrlPerClientPath(clientPath: string) {
    const picturePath = RouteUtils.picturePath();

    return picturePath + "/files/" + accountService.getId() + "/" + clientPath;


}


export const FileUtils = {
    formatDisplayLogo,
    displayBanner,
    getUrlPerClientPath
}