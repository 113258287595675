import React from 'react'

//React and Coreui Imports
import {CNavLink, CSidebar, CSidebarBrand, CSidebarFooter, CSidebarNav} from '@coreui/react';
import {useDispatch, useSelector} from 'react-redux';

//Components
import BuyerNavigationItems from "../../../Components/Buyer/Sidebar/BuyerNav";
import SidebarNavigation from "./SidebarNavigation";
import SupplierNavigationItems from "../../../Components/Supplier/Sidebar/SupplierNav";
import AffiliateNavigationItems from "../../../Components/Affiliate/Sidebar/AffiliateNav";
import SupplierContact from "../../../Components/Supplier/Contact/SupplierContact";
import AffiliateContact from "../../../Components/Affiliate/Contact/AffiliateContact";
//Assets
import logoBE from '../../../Assets/bobex-white-165.png';
import logoNL from '../../../Assets/bobexnl-white-165.png';
import logoCI from '../../../Assets/CI.png';
import logoVBK from '../../../Assets/verbouwkosten-logo.png';

//Custom Hooks
import {accountService} from "../../../Services/Helpers/AccountService";
import {RouteUtils} from "../../../utils/RouteUtils";

/**
 * Sidebar Component
 * Used to display the sidebar navigation, depending on user type.
 * @returns {React.ReactElement}
 */
export default function Sidebar(): React.ReactElement | null {

    const copyright = new Date().getFullYear();
    let user = accountService.getUser();
    const dispatch = useDispatch();
    const sidebarShow = useSelector((state: any) => state.SidebarReducer.sidebarShow);

    function getLogo() {

        if (RouteUtils.getContext() === 'be') {
            return logoBE;
        } else if (RouteUtils.getContext() === 'nl') {
            return logoNL;
        } else if (RouteUtils.getContext() === 'ci') {
            return logoCI;
        } else if (RouteUtils.getContext() === 'vbk') {
            return logoVBK;
        }
        return logoBE;
    }

    if (user !== null) {
        return (
            <CSidebar
                style={{maxHeight: "100vh"}}
                visible={sidebarShow}
                onVisibleChange={(visible) => {
                    dispatch({type: 'set', sidebarShow: visible})
                }}
            >
                <CSidebarBrand className="">
                    <CNavLink href={RouteUtils.getUserRedirectPage(user)}>
                        <img src={getLogo()} alt="bobex-logo" width="165" height="auto"/>
                    </CNavLink>
                </CSidebarBrand>
                <CSidebarNav className="mt-md-5 mt-0">
                    {user.authority === "buyer" && <SidebarNavigation items={BuyerNavigationItems}/>}
                    {user.authority === "supplier" && <SidebarNavigation items={SupplierNavigationItems()}/>}
                    {user.authority === "affiliate" && <SidebarNavigation items={AffiliateNavigationItems}/>}
                </CSidebarNav>

                <CSidebarFooter className={"px-0"}>
                    <div className={"contact-wrapper mb-3"}>
                        {user.authority === "supplier" &&
                            <SupplierContact/>

                        }
                        {user.authority === "affiliate" &&
                            <AffiliateContact/>

                        }
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="">
                            &copy; Bobex {copyright}
                        </div>
                    </div>
                </CSidebarFooter>
            </CSidebar>
        )
    }

    return null;
}