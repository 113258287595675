import React from "react";
import {CButton, CModal, CModalFooter, CModalHeader, CModalTitle} from "@coreui/react";
import {CModalBody} from "@coreui/react-pro";

interface InvoicesGeneratedModalProps {
    isOpen: boolean;
    handleCloseModal: () => void;
    t: (key: string) => string;
    generatedHtml: string
    isInvoice?: boolean
}

const InvoicesGeneratedModal: React.FC<InvoicesGeneratedModalProps> = ({
                                                                           isOpen,
                                                                           handleCloseModal,
                                                                           t,
                                                                           generatedHtml,
                                                                           isInvoice
                                                                       }) => {


    return (
        <CModal
            size="xl"
            alignment="center"
            visible={isOpen}
            onClose={() => {
                handleCloseModal();
            }}
        >
            <CModalHeader>
                <CModalTitle>
                    {
                        isInvoice ? t("Invoice in html") : t("Credit note in html")
                    }
                </CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div dangerouslySetInnerHTML={{__html: generatedHtml}}/>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => {
                    handleCloseModal();
                }}>
                    {t("Close")}
                </CButton>
            </CModalFooter>
        </CModal>
    )
}

export default InvoicesGeneratedModal;