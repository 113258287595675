import {PartnerSubmissionView} from "../PartnerSubmissionModel/PartnerSubmissionView";
import {ExpertBusinessTypeView} from "../ExpertBusinessType/ExpertBusinessTypeView";
import {TeamMemberView} from "../TeamMemberModel/TeamMemberView";

export class ExtranetFolderView {


    id: number;
    name: string;
    memberModelId: number;
    partnerSubmissionCount: number;
    expertBusinessTypeModels: ExpertBusinessTypeView[];
    teamMemberModels: TeamMemberView[];

    constructor(data?: any) {
        this.id = data?.id ?? -1;
        this.name = data?.name ?? "";
        this.memberModelId = data?.memberModelId ?? -1;
        this.partnerSubmissionCount = data?.partnerSubmissionCount ?? 0;
        this.expertBusinessTypeModels = data?.expertBusinessTypeModels ?? [];
        this.teamMemberModels = data?.teamMemberModels ?? [];
    }
}