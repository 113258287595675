function isEmpty(value:any) {
    return (value == null || (typeof value === "string" && value.trim().length === 0));
}

const filterDigits = (asString: string | null): string => {
    return asString && asString.length !== 0 ? asString.replaceAll(/\D+/g, "") : "";
};
export const GeneralUtils = {
    isEmpty,
    filterDigits,
}