import React, {FormEventHandler, useState} from "react";
import {
    CButton, CForm,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CModal,
    CModalFooter,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import {CModalBody} from "@coreui/react-pro";
import {MyCrmRequestService} from "../../../Services/Supplier/MyCrmRequestService";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";
import {MyCrmUpdateRequestDTO} from "../../../Data/request/Update/MyCrmUpdateRequestDTO";
import {BbxSearchSelect} from "../../../Shared/Components/SearchSelect/BbxSearchSelect";

interface MyCrmGeneratedModalProps {
    isUpdateModalVisible: boolean;
    setIsUpdateModalVisible: (isUpdateModalVisible: boolean) => void;
    getMyCrms: () => void;
    t: (key: string) => string;
    showToast: (type: BbxToastType, message: string) => void;
    myCrmUpdateRequestDTO: MyCrmUpdateRequestDTO;
    setMyCrmUpdateRequestDTO: (myCrmUpdateRequestDTO: MyCrmUpdateRequestDTO) => void;
    myCrmIdToDelete: number;
    setMyCrmIdToDelete: (myCrmIdToDelete: number) => void;
    partnerSubmissionId: number;
}


const MyCrmGeneratedModal: React.FC<MyCrmGeneratedModalProps> = ({
                                                                     isUpdateModalVisible,
                                                                     setIsUpdateModalVisible,
                                                                     getMyCrms,
                                                                     t,
                                                                     showToast,
                                                                     myCrmUpdateRequestDTO,
                                                                     setMyCrmUpdateRequestDTO,
                                                                     myCrmIdToDelete,
                                                                     setMyCrmIdToDelete,
                                                                     partnerSubmissionId

                                                                 }) => {

    const myCrmStatusOption = [
        {label: `${t("mycrm.status.10")}`, value: "10"},
        {label: `${t("mycrm.status.20")}`, value: "20"},
        {label: `${t("mycrm.status.30")}`, value: "30"},
    ];

    const myCrmPriorityOption = [
        {label: `${t("mycrm.priority.10")}`, value: "10"},
        {label: `${t("mycrm.priority.20")}`, value: "20"},
        {label: `${t("mycrm.priority.30")}`, value: "30"},
    ];

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (partnerSubmissionId > 0) {
            myCrmUpdateRequestDTO.partnerSubmissionId = partnerSubmissionId;
        }
        MyCrmRequestService.updateMyCrm(myCrmUpdateRequestDTO).then(() => {
            getMyCrms()
            setIsUpdateModalVisible(false);
            showToast(BbxToastType.SUCCESS, t("Comment has been saved successfully"));

        }).catch(() => {
            showToast(BbxToastType.ERROR, t("An error occured while saving new comment"));
        });
    }

    return (
        <>


            {/*create/update Modal*/}
            <CModal
                alignment={"center"}
                visible={isUpdateModalVisible}
                onClose={
                    () => {
                        setMyCrmUpdateRequestDTO(new MyCrmUpdateRequestDTO());
                        setIsUpdateModalVisible(false);
                    }
                }
            >
                <CModalHeader>
                    <h1>{t("Update comment")}</h1>
                </CModalHeader>
                <CForm onSubmit={onSubmit}>
                    <CModalBody className="search-form">


                        <CFormLabel>
                        <span>
                            {t("Status")}
                        </span>
                            <BbxSearchSelect
                                placeholder={t("Status")}
                                required={true}
                                sortOptions={false}
                                options={myCrmStatusOption}
                                value={myCrmUpdateRequestDTO.status.toString()}
                                onChange={(value: string | null) => {
                                    setMyCrmUpdateRequestDTO({
                                        ...myCrmUpdateRequestDTO,
                                        status: value ? Number(value) : -1
                                    });
                                }}
                            />
                        </CFormLabel>
                        <CFormLabel>
                        <span>
                            {t("Priority")}
                        </span>
                            <BbxSearchSelect
                                placeholder={t("Priority")}
                                options={myCrmPriorityOption}
                                sortOptions={false}
                                required={true}
                                value={myCrmUpdateRequestDTO.priority?.toString()}
                                onChange={(value: string | null) => {
                                    setMyCrmUpdateRequestDTO({
                                        ...myCrmUpdateRequestDTO,
                                        priority: value ? Number(value) : -1
                                    });
                                }}
                            />
                        </CFormLabel>
                        <CFormLabel>
                        <span>
                            {t("Description")}
                        </span>
                            <CFormTextarea
                                required={true}
                                placeholder={t("Description")}
                                value={myCrmUpdateRequestDTO.problemDesc}
                                onChange={(e) => {
                                    setMyCrmUpdateRequestDTO({...myCrmUpdateRequestDTO, problemDesc: e.target.value});
                                }}
                            />
                        </CFormLabel>
                        <CFormLabel>
                        <span>
                            {t("Solution")}
                        </span>
                            <CFormTextarea
                                placeholder={t("Solution")}
                                value={myCrmUpdateRequestDTO.solution}
                                onChange={(e) => {
                                    setMyCrmUpdateRequestDTO({...myCrmUpdateRequestDTO, solution: e.target.value});
                                }}
                            />
                        </CFormLabel>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color={"primary"}
                            type={"submit"}
                        >
                            {t("Save")}
                        </CButton>
                    </CModalFooter>
                </CForm>
            </CModal>


            {/*delete Modal*/}
            <CModal
                alignment={"center"}
                visible={myCrmIdToDelete !== 0}
                onClose={
                    () => {
                        setMyCrmIdToDelete(0);
                    }
                }
            >
                <CModalBody>
                    <p><b>{t("Are you sure you want to delete this comment ?")}</b></p>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={
                            () => {
                                setMyCrmIdToDelete(0);
                            }
                        }
                    >
                        {t("Cancel")}
                    </CButton>
                    <CButton
                        color="danger"
                        onClick={
                            () => {
                                MyCrmRequestService.removeMyCrm(myCrmIdToDelete).then(() => {
                                    setMyCrmIdToDelete(0);
                                    getMyCrms();
                                    showToast(BbxToastType.SUCCESS, t("Comment has been deleted successfully"));
                                }).catch(() => {
                                    showToast(BbxToastType.ERROR, t("An error occured while deleting comment"));
                                });
                            }
                        }
                    >
                        {t("Confirm")}
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default MyCrmGeneratedModal;