export class PageRequestDTO {
    page: number;
    size: number;
    sortColumn: string;
    sortDirection: string | number

    constructor(data?: any) {
        this.page = data?.page ?? 0;
        this.size = data?.size ?? 10;
        this.sortColumn = data?.sortColumn ?? "id";
        this.sortDirection = data?.sortDirection ?? "desc";
    }


}