const INVOICE_SENT = 40;
const PAYMENTS_PARTIAL = 45;
const PAYMENTS_DUBIOUS = 46;
const PAYMENTS_LAWYER = 47;
const PAYMENTS_LAWYER_COURT = 48;
const PAYMENTS_WITH_CREDIT_NOTE = 55;
const INVOICE_STATUS_PAID = 50;
const CREDIT_NOTE = 60;

export const InvoiceConstants = {
    INVOICE_SENT,
    PAYMENTS_PARTIAL,
    PAYMENTS_DUBIOUS,
    PAYMENTS_LAWYER,
    PAYMENTS_LAWYER_COURT,
    PAYMENTS_WITH_CREDIT_NOTE,
    INVOICE_STATUS_PAID,
    CREDIT_NOTE
}