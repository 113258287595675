//React and Coreui Imports
import React, {Suspense} from "react";
import {CContainer, CSpinner} from "@coreui/react";
import {Navigate, Routes, useLocation} from "react-router-dom";

//Components
import Header from "../../Shared/Components/Header/Header";
import Sidebar from "../../Shared/Components/Navigation/Sidebar";
import Breadcrumb from "../../Shared/Components/Navigation/Breadcrumb";
import {BobexSuppliersRoutes} from "./Sidebar/Routes";
import useMediaWidth from "../../CustomHooks/UseMediaWidth";
import LanguageSwitcher from "../../Shared/Components/Header/LanguageSwitcher";
import {accountService} from "../../Services/Helpers/AccountService";


/*
 * Root component of the supplier app.
 * Determines the layout of the supplier app with navigation and header.
 * @return {React.ReactElement}
 */
export default function SupplierApp(): React.ReactElement {
    const isResponsive = useMediaWidth(576);
    const user = accountService.getUser();
    const location = useLocation();

    if (user === null) {
        accountService.saveRedirectAfterLogin(location.pathname + location.search);
        return <Navigate to="/login"/>;
    }

    return (
        <>
            <div className="d-flex w-100">
                {/*<CSidebar*/}
                {/*    visible={true}*/}
                {/*>*/}
                {/*    <CSidebarNav>*/}
                {/*        <CAccordion*/}
                {/*            activeItemKey={1}*/}
                {/*        >*/}
                {/*            <CAccordionItem*/}
                {/*                itemKey={1}*/}
                {/*            >*/}
                {/*                <CAccordionHeader>Accordion Item #2</CAccordionHeader>*/}
                {/*                <CAccordionBody>*/}
                {/*                    <CNavItem*/}
                {/*                        active={true}*/}
                {/*                    >*/}

                {/*                        <>aaaaa</>*/}
                {/*                    </CNavItem>*/}
                {/*                </CAccordionBody>*/}
                {/*            </CAccordionItem>*/}
                {/*        </CAccordion>*/}
                {/*    </CSidebarNav>*/}
                {/*</CSidebar>*/}

                <Sidebar/>
                <CContainer className="bobex-app-content-wrapper">
                    <Header/>
                    <CContainer className="bobex-app-content pt-md-0 p-md-4 p-2 mb-sm-0 mx-auto mx-sm-0">
                        {isResponsive && <Breadcrumb/>}
                        {!isResponsive &&
                            <div className={"d-flex justify-content-between"}>
                                <Breadcrumb/>
                                <div className={"p-2"}>
                                    <LanguageSwitcher/>
                                </div>
                            </div>
                        }
                        <Suspense fallback={<CSpinner/>}>
                            <Routes>
                                {BobexSuppliersRoutes}
                            </Routes>
                        </Suspense>
                    </CContainer>
                </CContainer>
            </div>
        </>
    );
}