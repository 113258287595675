import axios, {AxiosError, AxiosStatic, InternalAxiosRequestConfig} from 'axios';
import {accountService} from "./AccountService";
import {useNavigate} from "react-router-dom";
import {ApiRoutes} from "../../ApiRoutes";

let isRetrying = false;

const Axios: AxiosStatic = axios.create({
    baseURL: ApiRoutes.APP_API_URL_BACKEND()
}) as AxiosStatic;


Axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    if (request && request.headers) {

        const accessToken = accountService.getAccessToken();
        const refreshToken = accountService.getRefreshToken();

        if (request.url && request.url.includes(ApiRoutes.REACT_APP_URL_BO_LOGIN)) {
            return request;
        }

        if (accountService.isLogged() && request.url !== ApiRoutes.REACT_APP_URL_REFRESH_TOKEN) {

            const token = request.url === ApiRoutes.REACT_APP_URL_LOGOUT ? refreshToken : accessToken;

            request.headers.Authorization = 'Bearer ' + token;
        }
    }

    return request;
})

let isRefreshing = false;
let refreshSubscribers: ((newToken: any) => void)[] = [];
const apiInterceptor = Axios.interceptors.response.use(
    response => response,
    async (error) => {
        if (error.code === "ERR_CANCELED") {
            return Promise.reject(error);
        }

        const {config, response} = error;
        const originalRequest = config;

        if (response) {
            if (response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        refreshSubscribers.push((accessToken) => {
                            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                            resolve(Axios(originalRequest));
                        });
                    });
                }

                originalRequest._retry = true;
                isRefreshing = true;

                try {
                    const accessToken = await accountService.refreshToken();
                    Axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                    refreshSubscribers.forEach(callback => callback(accessToken));
                    refreshSubscribers = [];
                    return Axios(originalRequest);
                } catch (refreshError) {
                    console.error("Refresh token invalid:", refreshError);
                    accountService.logout(); // Consider auto logout on persistent token issues
                    return Promise.reject(refreshError);
                } finally {
                    isRefreshing = false;
                }
            }
        }

        return Promise.reject(error);
    }
);

function removeInterceptor() {
    Axios.interceptors.response.eject(apiInterceptor);
}

export default Axios;