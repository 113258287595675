import {requestService} from "../Helpers/RequestService";
import {ApiRoutes} from "../../ApiRoutes";

async function login(credentials: any) {

    if (!ApiRoutes.REACT_APP_URL_AUTHENTICATE) {
        throw new Error("REACT_APP_API_URL_BACKEND is not defined");
    }

    return requestService.post(ApiRoutes.REACT_APP_URL_AUTHENTICATE, credentials)
}
export const GeneralRequestService = {
    login
}