import {BbxRequest} from "../../Data/request/BbxRequest";
import BbxRequestReducer from "./BbxRequestReducer";
import {MyCrmRequestDTO} from "../../Data/request/MyCrmRequestDTO";

export function AffiliatePricingRequestReducer(state: BbxRequest<MyCrmRequestDTO>, action: any): BbxRequest<MyCrmRequestDTO> {
    state = BbxRequestReducer(state, action);
    switch (action.type) {
        case 'SET_LEAD_ID':
            return {...state, searchForm: {...state.searchForm, leadId: action.payload}};


    }
    return state;
}