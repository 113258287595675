import React from 'react';

//React and Coreui Imports
import {useLocation} from 'react-router-dom';
import {CBreadcrumb, CBreadcrumbItem} from '@coreui/react';
import {useTranslation} from 'react-i18next';

//Components
import {SuppliersRoutes} from "../../../Components/Supplier/Sidebar/Routes";
import {BuyerRoutes} from "../../../Components/Buyer/Sidebar/Routes";

//Types
import {Routes} from "../../Interfaces/Routes.interface";
import {accountService} from "../../../Services/Helpers/AccountService";
import {RouteUtils} from "../../../utils/RouteUtils";
import {AffiliateRoutes} from "../../../Components/Affiliate/Sidebar/Routes";

/**
 * Breadcrumb component
 * Inserts a breadcrumb bar in each component depending on the current route
 * @return {React.ReactElement}
 */
export default function Breadcrumb(): React.ReactElement {
    const {t} = useTranslation();
    const currentLocation = useLocation().pathname;

    let user = accountService.getUser();
    if (!user.authority) {
        return <></>;
    }

    let routes: Routes[] = [];
    let prefix = "";
    if (user.authority === "supplier") {
        routes = SuppliersRoutes;
        prefix = "/supplier/";
    } else if (user.authority === "buyer") {
        routes = BuyerRoutes;
        prefix = "/buyer/";
    } else if (user.authority === "affiliate") {
        routes = AffiliateRoutes;
        prefix = "/affiliate/";
    }

    const getRouteName = (pathname: string, routes: Routes[]) => {
        for (let i = 0; i < routes.length; i++) {

            let routePath = routes[i];
            pathname = pathname.replace(prefix, "");

            const segments = pathname.split('/');

            // check if the last segment is a number
            const lastSegment = segments[segments.length - 1];
            if (/^\d+$/.test(lastSegment)) {
                // if it is, remove it from the segments array
                const numericSegment = segments.pop();

                // and join the remaining segments to get the base path
                const basePath = segments.join('/');

                if (routePath.path === basePath) {
                    return numericSegment;
                }

            } else {
                const updatedUrl = pathname.replace(/\/\d+/g, '/:id');

                if (routePath.path === updatedUrl) {
                    return routePath.name;
                }
            }

        }
        return "";
    };

    const getBreadcrumbs = (location: string, routes: Routes[]) => {
        const breadcrumbs: { pathname: string; name: string; active: boolean }[] = [];

        getRouteName(location, routes);

        location.split('/').reduce((prev: string, curr: string, index: number, array: string[]) => {
            const currentPathname = `${prev}/${curr}`
            const routeName: string | undefined = getRouteName(currentPathname, routes)
            if (routeName) {
                breadcrumbs.push({
                    pathname: currentPathname,
                    name: routeName,
                    active: index + 1 === array.length,
                })
            }
            return currentPathname;
        });
        return breadcrumbs;
    };

    const getCorrectBaseUrlWithUserAuthority = (userAuthority: string) => {

        const isLocalhost = window.location.hostname.includes('localhost');
        const basePath = isLocalhost ? baseURL : '';

        switch (userAuthority) {
            case "supplier":
                return (
                    <CBreadcrumbItem href={`${basePath}/${userAuthority}/activity/leads`}>My Bobex</CBreadcrumbItem>
                );
            case "buyer":
                return (
                    <CBreadcrumbItem href={`${basePath}/${userAuthority}/projects/overview`}>My Bobex</CBreadcrumbItem>
                );
            case "affiliate":
                return (
                    <CBreadcrumbItem href={`${basePath}/${userAuthority}/dashboard`}>My Bobex</CBreadcrumbItem>
                );
            default:
                return (
                    <CBreadcrumbItem href={`${basePath}/${userAuthority}/`}>My Bobex</CBreadcrumbItem>
                );
        }

    }

    let breadcrumbs = getBreadcrumbs(currentLocation, routes);

    let baseURL = "/extranet-" + RouteUtils.getContext();

    return (
        <CBreadcrumb className="m-0 mt-3 mt-md-0 ms-2 py-2">

            {getCorrectBaseUrlWithUserAuthority(user.authority)}

            {breadcrumbs.map((breadcrumb, index) => {
                return (
                    <CBreadcrumbItem
                        {...(breadcrumb.active ? {active: true} : {href: window.location.hostname.includes('localhost') ? baseURL : '' + breadcrumb.pathname})}
                        key={index}
                    >
                        {t(breadcrumb.name)}
                    </CBreadcrumbItem>
                );
            })}
        </CBreadcrumb>
    );
}
