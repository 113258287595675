//React and Coreui Imports
import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";

//Custom Hooks
import {accountService} from "../../../Services/Helpers/AccountService";
import {RouteUtils} from "../../../utils/RouteUtils";
import {useTemplateUpdate} from "../SidebarUpdateContextType/SidebarUpdateContextType";

//Types
interface ProtectedRouteProps {
    children: React.ReactNode;
}

/**
 * Checks if the user is authenticated and redirects to the login page if not.
 */
const useAuth = () => {
    const user = accountService.getUser();
    const token = accountService.getAccessToken();
    const refreshToken = accountService.getRefreshToken();


    if (user !== null) {
        return !!(user.loginId !== "" && user.authority !== "" && user.language !== "" && token && refreshToken);
    }
    return false;
}

/*
 * Creates a component that can be used as a protected route, to protect routes and views from unauthenticated users.
 */
// @ts-ignore
const ProtectedRoute = () => {
    const location = useLocation();
    const user = accountService.getUser();
    const isAuthenticated = useAuth();
    const {isPartnerRfpEmpty, setIsPartnerRfpEmpty} = useTemplateUpdate(); // Use the context


    if (user !== null) {
        let context = user.authority;

        if (((location.pathname).toLowerCase()).includes(((context).toLowerCase()))) {

            if (!RouteUtils.isRouteBlockedIfPartnerEmpty(location.pathname, isPartnerRfpEmpty)) {
                return <Navigate to={`/${context}/no-access`}/>;
            }

            if (user.isTeamMember && location.pathname !== `/${context}/no-access`) {
                if (RouteUtils.isRouteOnlyForAdminRole(location.pathname, user.teamMemberRole)) {
                    return isAuthenticated ? <Outlet/> : <Navigate to="/login"/>;
                } else {
                    return <Navigate to={`/${context}/no-access`}/>;
                }
            }
            return isAuthenticated ? <Outlet/> : <Navigate to="/login"/>;
        } else {
            return <Navigate to={`/${context}/no-access`}/>;
        }
    } else {
        return <Navigate to="/login"/>;
    }
};
export default ProtectedRoute;