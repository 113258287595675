import React, {useRef} from "react";
import {Page} from "../../../Data/Page";
import {CSmartPagination} from "@coreui/react-pro";
import {CCard, CCardBody, CCardFooter, CCardHeader, CCol, CFormSelect, CRow, CTable} from "@coreui/react";
import {useTranslation} from "react-i18next";
import useMediaWidth from "../../../CustomHooks/UseMediaWidth";
import {DateUtils} from "../../../utils/DateUtils";
import Loader from "../Loader/Loader";
import {BbxColumnType} from "./BbxColumnType";
import {BbxRequest} from "../../../Data/request/BbxRequest";
import {ClassesUtils} from "../../../utils/ClassesUtils";


interface BbxColumnProps<T> {
    path?: string,
    label: string,
    function?: (row: T) => any
    className?: string
    width?: string
    type?: BbxColumnType
    isDisabled?: boolean
}


interface bbxFilterProps {
    label: string,
    value: string,
}

interface BbxCardLayoutProps<T> {
    header?: {
        start?: (row: T) => React.ReactElement,
        end?: (row: T) => React.ReactElement,
        className?: (row: T) => string
    }
    body: (row: T) => React.ReactElement
    footer?: (row: T) => React.ReactElement

}

interface BbxTableProps<T> {
    className?: string
    page: Page<T>,
    request?: BbxRequest<any>,
    columns: BbxColumnProps<T>[],
    onPageChange: (page: number) => void
    onPageSizeChange?: (size: number) => void
    pageSizeOptions?: number[]
    hasHeader?: boolean
    hasPagination?: boolean
    displayAllItems?: boolean
    sortByElements?: bbxFilterProps[]
    onSortByChange: (value: string) => void
    onSortDirectionChange: (value: string) => void
    title: string | React.ReactElement
    mobileCardLayout?: BbxCardLayoutProps<T>
    customHeader?: React.ReactElement;

}


export default function BbxTable<T>(props: BbxTableProps<T>): React.ReactElement {

    const {t} = useTranslation();
    const isLargeScreen = useMediaWidth(1024);

    const hasHeader = props.hasHeader ?? true;
    const hasPagination = props.hasPagination ?? true;
    const displayAllItems = props.displayAllItems ?? true;

    const topOfComponent = useRef<HTMLDivElement>(null); // This creates a reference to a div element

    const isLoaded = props.page.isLoaded ?? true;

    const defaultPageSizeOptions = [10, 50, 100];
    const pageSizeOptions = props.pageSizeOptions ?? defaultPageSizeOptions;

    const uniqueKey = Math.random().toString(36).substring(7);

    let className = "position-relative ";
    className += props.className ?? ""
    return (

        <div className={className} key={uniqueKey}>
            <CRow ref={topOfComponent}>
                <CCol md={6}>
                    {props.customHeader ?
                        <>
                            <CRow>
                                <CCol md={6}>
                                    <h3 className={"mb-3"}>{props.title}</h3>
                                </CCol>
                                <CCol md={2} className="justify-content-start align-content-center">
                                    {props.customHeader}
                                </CCol>
                            </CRow>
                        </> :
                        <h3>{props.title}</h3>
                    }

                    {/*{props.customHeader && (
                        <CCol md={2} className="d-flex justify-content-start">
                            {props.customHeader}
                        </CCol>
                    )}*/}
                </CCol>
                {props.request &&
                    <CCol md={6} className={"d-flex justify-content-sm-end justify-content-start"}>
                        {props.sortByElements !== undefined && props.sortByElements.length > 0 &&
                            <div className={"d-flex flex-column flex-sm-row"}>
                                <label
                                    className={"mb-0 me-2 d-flex align-items-center justify-content-start justify-content-sm-center"}>{t("Sort by")}
                                </label>
                                <div>
                                    <CFormSelect
                                        className={"d-inline mb-2 m-1 w-auto pe-5"}
                                        value={props.request.sortColumn}
                                        onChange={(e) => {
                                            props.page.isLoaded = false;
                                            props.onSortByChange(e.target.value);
                                        }}
                                    >
                                        {props.sortByElements.map((filter, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={filter.value}
                                                >
                                                    {t(filter.label)}
                                                </option>
                                            );
                                        })}
                                    </CFormSelect>
                                    <CFormSelect
                                        className={"d-inline mb-2 m-1 w-auto pe-5"}
                                        value={props.request.sortDirection}
                                        onChange={(e) => {
                                            props.page.isLoaded = false;
                                            props.onSortDirectionChange(e.target.value);
                                        }}
                                    >
                                        <option value={"desc"}>{t("Desc")}</option>
                                        <option value={"asc"}>{t("Asc")}</option>
                                    </CFormSelect>
                                </div>
                            </div>

                        }
                    </CCol>
                }
            </CRow>

            {/*desktop*/}
            {isLargeScreen &&
                <CRow>
                    <CCol xs={12} className={"position-relative"} style={{overflowX: "scroll"}}>
                        {!isLoaded &&
                            <div
                                className={"position-absolute"}
                                style={{
                                    top: 0,
                                    left: "calc(var(--cui-gutter-x) * .5)",
                                    right: "calc(var(--cui-gutter-x) * .5)",
                                    bottom: 0,
                                    backgroundColor: "rgba(255, 255, 255, 0.65)"
                                }}>
                                <Loader/>
                            </div>
                        }

                        <CTable striped bordered align="middle" responsive={"md"}>
                            {hasHeader &&
                                <thead>
                                <tr>
                                    {props.columns.map((column, index) => {
                                        return (
                                            column.isDisabled ? "" : <th key={index}>{column.label}</th>
                                        )
                                    })
                                    }
                                </tr>
                                </thead>
                            }
                            <tbody>
                            {props.page.content.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        {props.columns.map((column, index) => {
                                            const columnStyle = column.width ? {width: column.width} : {};
                                            return (
                                                column.isDisabled ? "" :
                                                    <td key={index} className={column.className ?? ""}
                                                        style={columnStyle}>
                                                        <>
                                                            {
                                                                column.function &&
                                                                column.function(row)
                                                            }
                                                            {
                                                                column.path &&
                                                                <p>
                                                                    {
                                                                        column.type === BbxColumnType.DATE &&
                                                                        DateUtils.formatLocal(ClassesUtils.getNestedObjectFromPath(row, column.path))
                                                                    }

                                                                    {
                                                                        column.type === BbxColumnType.MESSAGE &&
                                                                        t(ClassesUtils.getNestedObjectFromPath(row, column.path))
                                                                    }
                                                                    {
                                                                        column.type === undefined &&
                                                                        ClassesUtils.getNestedObjectFromPath(row, column.path)
                                                                    }
                                                                </p>
                                                            }
                                                        </>

                                                    </td>

                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            {props.page.content.length === 0 &&
                                <tr>
                                    <td colSpan={props.columns.length}
                                        className={"text-center"}>{t("No data available")}</td>
                                </tr>
                            }
                            </tbody>
                        </CTable>
                    </CCol>
                </CRow>
            }


            {/*card*/}
            {!isLargeScreen && props.mobileCardLayout &&
                <div className={"position-relative"}>
                    {!isLoaded &&
                        <div
                            className={"position-absolute"}
                            style={{
                                top: 0,
                                left: "0",
                                right: "0",
                                bottom: 0,
                                backgroundColor: "rgba(255, 255, 255, 0.65)",
                                zIndex: 100000
                            }}>
                            <Loader/>
                        </div>
                    }
                    {
                        props.page.content.map((row: T, index: number) => {
                            return (
                                <CCard className="mb-3 px-0" key={index}>
                                    {props.mobileCardLayout?.header !== undefined &&

                                        <CCardHeader
                                            className={props.mobileCardLayout.header.className !== undefined ? props.mobileCardLayout.header.className(row) : ""}
                                            style={{backgroundColor: "var(--light-blue)"}}>
                                            <CRow>
                                                <CCol xs={props.mobileCardLayout.header?.end ? 6 : 12}>
                                                    {props.mobileCardLayout.header.start ? props.mobileCardLayout.header.start(row) : undefined}
                                                </CCol>
                                                {props.mobileCardLayout.header?.end &&
                                                    <CCol xs={6} className={"text-end"}>
                                                        {props.mobileCardLayout.header.end(row)}
                                                    </CCol>
                                                }
                                            </CRow>
                                        </CCardHeader>
                                    }

                                    {props.mobileCardLayout?.body !== undefined &&
                                        <CCardBody>
                                            {props.mobileCardLayout?.body(row)}
                                        </CCardBody>
                                    }

                                    {props.mobileCardLayout?.footer !== undefined &&
                                        <CCardFooter style={{backgroundColor: "var(--light-blue)"}}>
                                            {props.mobileCardLayout?.footer(row)}
                                        </CCardFooter>
                                    }
                                </CCard>
                            )
                        })}
                </div>
            }
            {/* Pagination and page size selector */}
            {hasPagination &&
                <CRow className={"mt-3"}>
                    <CCol md={{span: 8, offset: 2}}>
                        {
                            props.page.totalPages > 1 &&
                            <CSmartPagination
                                align="center"
                                pages={props.page.totalPages}
                                activePage={props.page.number + 1}
                                onActivePageChange={(pageNumber: number) => {
                                    props.page.isLoaded = false;

                                    props.onPageChange(pageNumber - 1);

                                    setTimeout(() => {
                                        topOfComponent.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start",
                                        });
                                    }, 0);

                                }}
                            />
                        }
                    </CCol>
                    {
                        props.onPageSizeChange && props.page.totalElements !== 1 && (
                            <CCol md={2} xs={12}
                                  className={"d-flex justify-content-md-end justify-content-center mt-3 mt-md-0"}>
                                <CFormSelect
                                    value={props.page.size}
                                    onChange={(e) => {
                                        props.page.isLoaded = false;
                                        props.onPageSizeChange && props.onPageSizeChange(Number(e.target.value));
                                    }}
                                    className={"d-inline mb-2 m-1 w-auto pe-5"}
                                >
                                    {pageSizeOptions.map((option, index) => (
                                        <option key={index}
                                                value={option}>{option + " " + t("items per page")}</option>
                                    ))}
                                    {props.page.totalElements > 0 && displayAllItems &&
                                        <option value={props.page.totalElements}>{t("All items")}</option>}
                                </CFormSelect>
                            </CCol>
                        )
                    }
                </CRow>
            }
        </div>
    )
        ;
}