export const CookieUtils = {
    setCookie: (name: string, value: string, date: Date) => {
        document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    },
    getCookie: (name: string) => {
        const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
        return value ? value[2] : null;
    },
    deleteCookie: (name: string) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    },
}