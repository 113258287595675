import Axios from "./AxiosInterceptorsService";
import {AxiosError} from "axios";

const post = async (url: string, data: any, params: Record<string, any> = {}) => {
    try {
        // Append the cache-busting parameter directly to the URL
        const cacheBustedUrl = `${url}?_t=${new Date().getTime()}`;

        let params2: any = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Cache-Control": "no-store",  // Prevent caching
                Pragma: "no-cache",// For older browsers
            },
            ...params
        };

        return await Axios.post(cacheBustedUrl, data, params2);
    } catch (e: any) {
        throw e;
    }
};


const get = async (url: string, data: any = {}, header: any = {}) => {

    try {
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Cache-Control": "no-store",  // Prevent caching
            Pragma: "no-cache",           // For older browsers
            ...header
        }

        // Add a cache-busting parameter to the request
        const cacheBuster = {_t: new Date().getTime()};

        return await Axios.get(url, {
            signal: data.signal,
            params: {...data, ...cacheBuster},
            headers: headers,

        });
    } catch (e: any) {
        throw e;
    }
}

const put = async (url: string, data: any, config = {} as any) => {

    try {
        return await Axios.put(url, data, config);
    } catch (e: any) {
        throw e;
    }
}

const del = async (url: string, data: any) => {

    try {
        return await Axios.delete(url, data);
    } catch (e: any) {
        throw e;
    }
}

export const requestService = {
    post,
    get,
    put,
    del
}