import React, {Suspense} from "react";
import Sidebar from "../../Shared/Components/Navigation/Sidebar";
import {CContainer, CSpinner} from "@coreui/react";
import Header from "../../Shared/Components/Header/Header";
import Breadcrumb from "../../Shared/Components/Navigation/Breadcrumb";
import {Navigate, Routes} from "react-router-dom";
import {BobexAffiliateRoutes} from "./Sidebar/Routes";
import {accountService} from "../../Services/Helpers/AccountService";
import useMediaWidth from "../../CustomHooks/UseMediaWidth";
import LanguageSwitcher from "../../Shared/Components/Header/LanguageSwitcher";


/*
 * Root component of the Affiliate app.
 * Determines the layout of the affiliate app with navigation and header.
 * @return {React.ReactElement}
 */
export default function AffiliateApp(): React.ReactElement {

    const user = accountService.getUser();
    const isResponsive = useMediaWidth(576);

    if (user === null) {
        return <Navigate to="/login"/>;
    }

    return (
        <>
            <div className="d-flex w-100">
                <Sidebar/>
                <CContainer className="bobex-app-content-wrapper">
                    <Header/>
                    <CContainer className="bobex-app-content pt-md-0 p-md-4 p-2 mb-sm-0 mx-auto mx-sm-0">
                        {isResponsive && <Breadcrumb/>}
                        {!isResponsive &&
                            <div className={"d-flex justify-content-between"}>
                                <Breadcrumb/>
                                <div className={"p-2"}>
                                    <LanguageSwitcher/>
                                </div>
                            </div>
                        }
                        <Suspense fallback={<CSpinner/>}>
                            <Routes>
                                {BobexAffiliateRoutes}
                            </Routes>
                        </Suspense>
                    </CContainer>
                </CContainer>
            </div>
        </>
    );
}