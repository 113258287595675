import {
    CButton,
    CCard,
    CCardBody,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader
} from "@coreui/react";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {FolderRequestService} from "../../../Services/Supplier/FolderRequestService";
import {ExtranetFolderView} from "../../../Data/view/ExtranetFolderModel/ExtranetFolderView";
import BbxTable from "../../../Shared/Components/Table/BbxTable";
import {Page} from "../../../Data/Page";
import {BbxRequest} from "../../../Data/request/BbxRequest";
import {FolderUpdateRequestDTO} from "../../../Data/request/Update/FolderUpdateRequestDTO";
import {useTemplateUpdate} from "../../../Shared/Components/SidebarUpdateContextType/SidebarUpdateContextType";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";
import {cilTrash} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";

export default function Folders(): React.ReactElement {

    const {t} = useTranslation();

    const {showToast} = useTemplateUpdate();

    const [folders, setFolders] = useState<Page<ExtranetFolderView>>(new Page<ExtranetFolderView>({isLoaded: false}));
    const [request, setRequest] = useState(new BbxRequest<Object>(Object));
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
    const [folderUpdateRequestDTO, setFolderUpdateRequestDTO] = useState(new FolderUpdateRequestDTO());
    const [folderIdToDelete, setFolderIdToDelete] = useState(0);
    const {refreshFolders, folderView} = useTemplateUpdate(); // Use the context

    function getFolder() {
        setFolders(folderView);
    }

    useEffect(() => {
        getFolder();
    }, [folderView]);

    return (
        <>
            <div className={"mb-3"}>
                <h1>
                    {t("Folders")}

                </h1>
                <CCard>
                    <CCardBody>

                        <BbxTable
                            page={folders}
                            request={request}
                            columns={
                                [
                                    {
                                        label: t("Name"),
                                        path: "name",
                                    },
                                    {
                                        label: t("Lead count"),
                                        function: (row: ExtranetFolderView) => {
                                            return row.partnerSubmissionCount;
                                        }

                                    },
                                    {
                                        label: t("Action"),
                                        function: (row: ExtranetFolderView) => {
                                            return (
                                                <>
                                                    <CButton
                                                        color="primary"
                                                        onClick={
                                                            () => {
                                                                setFolderUpdateRequestDTO(new FolderUpdateRequestDTO(row));
                                                                setIsUpdateModalVisible(true);
                                                            }
                                                        }
                                                    >
                                                        {t("Edit")}
                                                    </CButton>
                                                    <CButton
                                                        className={"ms-3"}
                                                        color="danger"
                                                        onClick={
                                                            () => {
                                                                setFolderIdToDelete(row.id);
                                                            }
                                                        }
                                                    >
                                                        {t("Delete")}
                                                    </CButton>
                                                </>
                                            );
                                        }
                                    }

                                ]
                            }
                            onPageChange={
                                () => {
                                }
                            }
                            onSortByChange={
                                () => {
                                }
                            }
                            onSortDirectionChange={
                                () => {
                                }
                            }
                            title={

                                <div className={"mb-3"}>

                                    {folders.content.length + " " + t("Folder(s)")}
                                    <CButton
                                        color={"primary"}
                                        className={"ms-3 "}
                                        onClick={
                                            () => {
                                                setIsUpdateModalVisible(true);
                                            }
                                        }
                                    >
                                        {t("Add new folder")}
                                    </CButton>
                                </div>
                            }
                            mobileCardLayout={
                                {
                                    header: {
                                        start: (item: any) => <p>{t(` ${item.name}`)}</p>,
                                        end: (item: any) => <p>{"Lead count: "}{item.partnerSubmissionCount}</p>,
                                    },
                                    body: (item: any) => <div className={"d-flex justify-content-center gap-5"}>
                                        <CButton
                                            color="primary"
                                            onClick={
                                                () => {
                                                    setFolderUpdateRequestDTO(new FolderUpdateRequestDTO(item));
                                                    setIsUpdateModalVisible(true);
                                                }
                                            }
                                        >
                                            <FontAwesomeIcon icon={faPenToSquare}/>
                                        </CButton>
                                        <CButton
                                            className={"ms-3"}
                                            color="danger"
                                            onClick={
                                                () => {
                                                    setFolderIdToDelete(item.id);
                                                }
                                            }
                                        >
                                            <CIcon icon={cilTrash}/>
                                        </CButton>
                                    </div>,
                                }
                            }
                        />
                    </CCardBody>


                </CCard>
            </div>
            <CModal
                alignment={"center"}
                visible={isUpdateModalVisible}
                onClose={
                    () => {
                        setFolderUpdateRequestDTO(new FolderUpdateRequestDTO());
                        setIsUpdateModalVisible(false);
                    }
                }
            >
                <CModalHeader>
                    <h1>{t("Add new folder")}</h1>
                </CModalHeader>
                <CModalBody className="search-form">
                    <CFormLabel>
                        <span>
                            {t("Name")}
                        </span>
                        <CFormInput
                            type={"text"}
                            placeholder={t("Name")}
                            value={folderUpdateRequestDTO.name}
                            onChange={(e) => {
                                setFolderUpdateRequestDTO({...folderUpdateRequestDTO, name: e.target.value});
                            }}
                        />
                    </CFormLabel>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color={"primary"}
                        onClick={
                            () => {
                                FolderRequestService.updateFolder(folderUpdateRequestDTO).then(() => {
                                    setIsUpdateModalVisible(false);
                                    getFolder();
                                    refreshFolders();
                                    showToast(BbxToastType.SUCCESS, t("Folder has been saved successfully"));

                                }).catch(() => {
                                    showToast(BbxToastType.ERROR, t("An error occured while saving new file or folder name already exist"));
                                });
                            }
                        }
                    >
                        {t("Save")}
                    </CButton>
                </CModalFooter>


            </CModal>

            <CModal
                alignment={"center"}
                visible={folderIdToDelete !== 0}
                onClose={
                    () => {
                        setFolderIdToDelete(0);
                    }
                }
            >
                <CModalBody>
                    <p><b>{t("Are you sure you want to delete this folder ?")}</b></p>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="secondary"
                        onClick={
                            () => {
                                setFolderIdToDelete(0);
                            }
                        }
                    >
                        {t("Cancel")}
                    </CButton>
                    <CButton
                        color="danger"
                        onClick={
                            () => {
                                FolderRequestService.removeFolder(folderIdToDelete).then(() => {
                                    getFolder();
                                    setFolderIdToDelete(0);
                                    refreshFolders();
                                    showToast(BbxToastType.SUCCESS, t("Folder has been deleted successfully"));
                                }).catch(() => {
                                    showToast(BbxToastType.ERROR, t("An error occured while deleting folder"));
                                });
                            }
                        }
                    >
                        {t("Delete")}
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}
