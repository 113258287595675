export class OgonePropertiesDTO {

    pspid: string;
    environment: string;
    key: string;
    context: string;
    typeM: string;
    typeP: string;

    constructor(data?: any) {
        this.pspid = data?.pspid ?? "";
        this.environment = data?.environment ?? "";
        this.key = data?.key ?? "";
        this.context = data?.context ?? "";
        this.typeM = data?.typeM ?? "";
        this.typeP = data?.typeP ?? "";
    }
}