import React, {useState} from "react";
import {CButton, CForm, CFormInput} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {BbxRequest} from "../../../Data/request/BbxRequest";
import {useNavigate} from "react-router-dom";
import {PartnerRfpRequestDTO} from "../../../Data/request/PartnerRfpRequestDTO";
import {useTranslation} from "react-i18next";

export default function AffiliateSearchHeader(): React.ReactElement {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const [partnerRfpRequestDTO, setPartnerRfpRequestDTO] = useState<BbxRequest<PartnerRfpRequestDTO>>(new BbxRequest<PartnerRfpRequestDTO>(PartnerRfpRequestDTO));

    function submit(partnerRfpRequestDTOBbxRequest: BbxRequest<PartnerRfpRequestDTO>) {

        if (partnerRfpRequestDTOBbxRequest.data.id > 0) {
            const searchId = partnerRfpRequestDTOBbxRequest.data.id;
            navigate(`/affiliate/leads/my-leads?id=${searchId}`);
        }
        setPartnerRfpRequestDTO(new BbxRequest<PartnerRfpRequestDTO>(PartnerRfpRequestDTO));
        return;
    }

    return (
        <div className="search-lead-input-wrapper col-12 d-none d-xl-flex justify-content-start">
            <CForm
                className="d-flex bg-white rounded-pill"
                onSubmit={
                    (e) => {
                        e.preventDefault();
                        submit(partnerRfpRequestDTO);
                    }
                }>
                <CFormInput type="text"
                            pattern="\d*"
                            placeholder={t("Lead number")}
                            size="sm"
                            className="my-auto me-2"
                    // Prevent pasting non-numeric characters
                            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                                const text = e.clipboardData.getData('text');
                                if (!/^\d+$/.test(text)) {
                                    e.preventDefault();
                                }
                            }}
                    // Allow only numbers
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                // allow Ctrl+V
                                if (e.ctrlKey && e.key === 'v') {
                                    return;
                                }

                                if (!/[0-9]/.test(e.key) &&
                                    !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Enter', 'Delete'].includes(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={
                                (e) => {
                                    const inputText = e.target.value;
                                    const numericValue = inputText.replace(/\D/g, ''); // Remove non-numeric characters
                                    setPartnerRfpRequestDTO({
                                        ...partnerRfpRequestDTO,
                                        data: {
                                            ...partnerRfpRequestDTO.data,
                                            id: parseInt(numericValue)
                                        }
                                    });
                                }}/>
                <div className="my-auto">
                    <CButton
                        type="submit"
                        color="secondary"
                        variant="outline"
                        shape="rounded-pill"
                    >
                        <FontAwesomeIcon icon={faSearch}/>
                    </CButton>

                </div>
            </CForm>
        </div>
    );
}