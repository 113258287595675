export class CreditNoteDTO{

    id: number;
    creditDate: Date;
    creditReference: string;
    creditAmount: number;
    vatExempted:boolean;
    invoiceReference: string;
    invoiceId: number;

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.creditDate = data?.creditDate ?? "";
        this.creditReference = data?.creditReference ?? "";
        this.creditAmount = data?.creditAmount ?? 0;
        this.vatExempted = data?.vatExempted ?? false;
        this.invoiceReference = data?.invoiceReference ?? "";
        this.invoiceId = data?.invoiceId ?? 0;
    }



}