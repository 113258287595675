export class ExtranetFolderRequestDTO {

    folderIds: number[];
    partnerSubmissionId: number;
    expertBusinessTypeId: number;

    constructor(data?: any) {
        this.folderIds = data?.folderIds ?? [];
        this.partnerSubmissionId = data?.partnerSubmissionId ?? null;
        this.expertBusinessTypeId = data?.expertBusinessTypeId ?? null;
    }
}