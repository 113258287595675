//React and Coreui Imports
import React from "react";
import {useTranslation} from "react-i18next";
import {CNav, CNavItem, CNavLink} from "@coreui/react";
import {useLocation, useNavigate} from "react-router-dom";
import {accountService} from "../../../Services/Helpers/AccountService";


/**
 * Contains the tabs to navigate between the different pages.
 * @return {React.ReactElement}
 */
export default function AccountTabs(): React.ReactElement {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const user = accountService.getUser();

    const tabs = [
        { path: "/supplier/activity/categories-and-work-areas", label: t("My categories & regions") },
        { path: "/supplier/account/invoices", label: t("Invoices") },
        { path: "/supplier/account/credit-notes", label: t("CreditNotes") },
        { path: "/supplier/account/team-members", label: t("Team") },
        { path: "/supplier/account/contact-details", label: t("Contact details") },
        { path: "/supplier/account/profile", label: t("Profile") },
        { path: "/supplier/account/password", label: t("Password") }
    ];

    return (
        <div>
            <h1>{t("My account")}</h1>
            <CNav variant="tabs" role="tablist" className="bobex-tabs flex-column flex-sm-row">
                {tabs.map((tab, index) =>
                    (user.teamMemberRole === -1 || user.teamMemberRole === 1 || tab.path === "/supplier/account/password") ? (
                        <CNavItem key={index} className="cursor-pointer">
                            <CNavLink
                                onClick={() => navigate(tab.path)}
                                active={location === tab.path}
                            >
                                {tab.label}
                            </CNavLink>
                        </CNavItem>
                    ) : null
                )}
            </CNav>
        </div>
    );
};