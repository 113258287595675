//React and Coreui Imports
import React, {useEffect, useState} from "react";

//Data
import {AccountManagerView} from "../../../Data/view/MemberModel/AccountManagerView";
import {accountService} from "../../../Services/Helpers/AccountService";
import {useTranslation} from "react-i18next";

/**
 * Display a container with the contact information of the Bobex Account Manager
 * @return {React.ReactElement}
 */
export default function SupplierContact(): React.ReactElement {
    const {t} = useTranslation();
    const [accountManager, setAccountManager] = useState<AccountManagerView>(new AccountManagerView());
    const [Loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        accountService.getAccountManager().then((response) => {
            setAccountManager(response);
            setLoading(false);
        });
    }, []);

    if (Loading) {
        return <></>;
    }

    return (
        <>
            <div className={"d-flex justify-content-around align-items-center"}>
                <div>
                    <i className="fal fa-comments fa-2x"></i>
                </div>
                <div>
                    <p className={"bold mb-2"}>{t("Your contact")}</p>
                    <p>{accountManager.accountManager}</p>
                    <p className={"link-orange"}><a
                        href={`tel:${accountManager.accountManagerPhone}`}>{accountManager.accountManagerPhone}</a></p>
                    <p><a href={`mailto:${accountManager.accountManagerEmail}`}>{accountManager.accountManagerEmail}</a>
                    </p>
                </div>
            </div>
        </>
    )
};