export class FolderUpdateRequestDTO {

    id: number;

    name: string;

    teamMemberIds: number[];

    ExpertBusinessTypeIds: number[];

    PartnerSubmissionIds: number[];

    constructor(data?: any) {
        this.id = data?.id ?? -1;
        this.name = data?.name ?? "";
        this.teamMemberIds = data?.teamMemberIds ?? [];
        this.ExpertBusinessTypeIds = data?.ExpertBusinessTypeIds ?? [];
        this.PartnerSubmissionIds = data?.PartnerSubmissionIds ?? [];
    }
}