//Used for the sidebar navigation
export class AccountManagerView {

    accountManager: string;
    accountManagerPhone: string;
    accountManagerEmail: string;

    constructor(data?: any) {
        this.accountManager = data?.accountManager ?? ""
        this.accountManagerPhone = data?.accountManagerPhone ?? ""
        this.accountManagerEmail = data?.accountManagerEmail ?? ""
    }
}