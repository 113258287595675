import {useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {TeamLeaderService} from "../../../../Services/Supplier/TeamLeaderService";
import {TeamLeaderRequestDTO} from "../../../../Data/request/TeamLeaderRequestDTO";
import {useTranslation} from "react-i18next";

export default function TeamLeaderAuthorizer() {
    let {deal} = useParams();
    const {t} = useTranslation();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');
    const navigate = useNavigate();


    const authorizeTeamLeader = () => {
        const teamLeaderRequestDTO = new TeamLeaderRequestDTO();
        teamLeaderRequestDTO.deal = deal === "deal";
        teamLeaderRequestDTO.code = code;
        teamLeaderRequestDTO.state = state;

        TeamLeaderService.teamLeaderAuthorize(teamLeaderRequestDTO).then(() => {
            navigate('/supplier/data-management/integration', { state: { message: t('Teamleader integration successful!'), type: 'success' } });
        }).catch(() => {
            navigate('/supplier/data-management/integration', { state: { message: t('Teamleader integration failed!'), type: 'error' } });
        });
    }

    useEffect(() => {
        authorizeTeamLeader();
    }, []);

    return (
        <div>
            <h1>TeamLeaderAuthorizer</h1>
        </div>
    );
}