import {RouteUtils} from "./RouteUtils";


function VATCalculate(amount: number) {



        const VATAmount = (amount * VatUtil.getVatByContext())
        const amountVATInculded = amount + VATAmount;
        return amountVATInculded;
}

function getVatByContext() {
    switch (RouteUtils.getContext()) {
        case "vbk":
            return VatUtil.NETHERLANDS;
        case "be":
        case "nl":
        case "ci":
            return VatUtil.BELGIUM;
    }throw new Error("Context not found");
}

export const VatUtil = {
    VATCalculate,
    getVatByContext,
    BELGIUM: 0.21,
    NETHERLANDS: 0.21,
}