import {CButton, CCard, CCardBody} from "@coreui/react";
import {useTranslation} from "react-i18next";
import React, {useEffect, useReducer, useState} from "react";
import {MyCrmRequestService} from "../../../Services/Supplier/MyCrmRequestService";
import {MyCrmView} from "../../../Data/view/MyCrmModel/MyCrmView";
import BbxTable from "../../../Shared/Components/Table/BbxTable";
import {Page} from "../../../Data/Page";
import {BbxRequest} from "../../../Data/request/BbxRequest";
import {useTemplateUpdate} from "../../../Shared/Components/SidebarUpdateContextType/SidebarUpdateContextType";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";
import {cilTrash} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {MyCrmUpdateRequestDTO} from "../../../Data/request/Update/MyCrmUpdateRequestDTO";
import {DateUtils} from "../../../utils/DateUtils";
import {RouteUtils} from "../../../utils/RouteUtils";
import {LanguageUtils} from "../../../utils/LanguageUtils";
import {MyCrmRequestDTO} from "../../../Data/request/MyCrmRequestDTO";
import MyCrmGeneratedModal from "./MyCrmGeneratedModal";
import {PartnerRfpRequestReducer} from "../../../Shared/Reducers/PartnerRfpRequestReducer";
import {PartnerRfpRequestDTO} from "../../../Data/request/PartnerRfpRequestDTO";
import {AffiliatePricingRequestReducer} from "../../../Shared/Reducers/ReducersCommentsRequestReducer";
import RequestUtil from "../../../utils/RequestUtil";
import {useNavigate} from "react-router-dom";

export default function Comments(): React.ReactElement {

    const {t} = useTranslation();

    const {showToast} = useTemplateUpdate();

    const [mycrms, setMyCrms] = useState<Page<MyCrmView>>(new Page<MyCrmView>({isLoaded: false}));
    const [state, dispatch] = useReducer(AffiliatePricingRequestReducer, new BbxRequest<MyCrmRequestDTO>(MyCrmRequestDTO, {}));
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
    const [myCrmUpdateRequestDTO, setMyCrmUpdateRequestDTO] = useState(new MyCrmUpdateRequestDTO());
    const [myCrmIdToDelete, setMyCrmIdToDelete] = useState(0);
    const navigate = useNavigate();

    function getMyCrms(newRequest?: BbxRequest<MyCrmRequestDTO>) {

        if (newRequest == null) {
            newRequest = state as BbxRequest<MyCrmRequestDTO>;
        }

        setMyCrms({...mycrms, isLoaded: false});

        MyCrmRequestService.getMyCrms(newRequest).then((response) => {
            setMyCrms(response)
        }).catch(() => {
            showToast(BbxToastType.ERROR, t("An error occurred while loading the comments"));
        });
    }

    useEffect(() => {
        getMyCrms();
    }, []);


    let baseUrl = window.location.hostname.includes('localhost') ? '/extranet-' + RouteUtils.getContext() : '';

    const handlePageChange = (newPage: number) => {
        dispatch({type: 'SET_REQUEST', payload: RequestUtil.switchPage(state, newPage)});
        getMyCrms(RequestUtil.switchPage(state, newPage));
    }


    const onSortDirectionChange = (value: string) => {
        dispatch({type: 'SET_REQUEST', payload: RequestUtil.switchSortDirection(state, value)});
        getMyCrms(RequestUtil.switchSortDirection(state, value));
    }

    const onSortColumnChange = (value: string) => {
        dispatch({type: 'SET_REQUEST', payload: RequestUtil.switchSortColumn(state, value)});
        getMyCrms(RequestUtil.switchSortColumn(state, value));
    }
    return (
        <>
            <div className={"mb-3"}>
                <h1>
                    {t("My CRM")}

                </h1>
                <CCard>
                    <CCardBody>

                        <BbxTable
                            sortByElements={[
                                {label: t("Last action date"), value: "id"},
                                {label: t("Status"), value: "status"},
                                {label: t("Priority"), value: "priority"},
                            ]}
                            page={mycrms}
                            request={state}
                            columns={
                                [
                                    {
                                        label: t("Lead"),
                                        function: (row: MyCrmView) => {
                                            return (
                                                <>
                                                    <p><a
                                                        onClick={(e: any) => {
                                                            e.preventDefault();
                                                            navigate(`/supplier/activity/leads/${row.partnerRfpId}`);
                                                        }}
                                                        href={`${baseUrl}/supplier/activity/leads/${row.partnerRfpId}`}>{t(row.businessName)}: {row.partnerRfpId}
                                                    </a>
                                                    </p>
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        label: t("Status"),
                                        function: (row: MyCrmView) => {
                                            return (
                                                <>
                                                    {t(LanguageUtils.getMyCrmStatusName(row.status))}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        label: t("Priority"),
                                        function: (row: MyCrmView) => {
                                            return (
                                                <>
                                                    {t(LanguageUtils.getMyCrmPriorityName(row.priority))}
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        label: t("User"),
                                        function: (row: MyCrmView) => {
                                            return (
                                                <>
                                                    {row.teamMemberModelId ? <p>{row.teamMemberModelFirstname}</p> :
                                                        <p>{row.memberModelFirstName}</p>}
                                                </>
                                            );
                                        }
                                    },

                                    {
                                        label: t("Description"),
                                        function: (row: MyCrmView) => {
                                            return (
                                                <>
                                                    <p>{row.problemDesc}</p>
                                                    <p>{row.solution}</p>
                                                </>
                                            )
                                        }
                                    },
                                    {
                                        label: t("Last action date"),
                                        function: (row: MyCrmView) => {
                                            return DateUtils.formatLocalWithTime(row.lastActionDate)
                                        }
                                    },
                                    {
                                        label: t("Action"),
                                        function: (row: MyCrmView) => {
                                            return (
                                                <>
                                                    <CButton
                                                        color="primary"
                                                        onClick={
                                                            () => {
                                                                setMyCrmUpdateRequestDTO(new MyCrmUpdateRequestDTO(row));
                                                                setIsUpdateModalVisible(true);
                                                            }
                                                        }
                                                    >
                                                        {t("Edit")}
                                                    </CButton>
                                                    <CButton
                                                        className={"ms-3"}
                                                        color="danger"
                                                        onClick={
                                                            () => {
                                                                setMyCrmIdToDelete(row.id);
                                                            }
                                                        }
                                                    >
                                                        {t("Delete")}
                                                    </CButton>
                                                </>
                                            );
                                        }
                                    }

                                ]
                            }
                            onPageChange={handlePageChange}
                            onSortByChange={onSortColumnChange}
                            onSortDirectionChange={onSortDirectionChange}
                            title={
                                <div className={"mb-3"}>
                                    {mycrms.totalElements + " " + t("Comment(s)")}
                                </div>
                            }
                            mobileCardLayout={
                                {
                                    header: {
                                        start: (item: any) =>
                                            <p>{t(LanguageUtils.getMyCrmStatusName(item.status))} | {t(LanguageUtils.getMyCrmPriorityName(item.priority))} | {item.memberModelFirstName}
                                            </p>,
                                        end: (item: any) => <p><a
                                            href={`${baseUrl}/supplier/activity/leads/${item.partnerRfpId}`}>{t(`${item.businessName}`)}: {item.partnerRfpId}</a>
                                        </p>,
                                    },
                                    body: (item: any) => {
                                        return (
                                            <>
                                                <div className="lead-info">
                                                    <p className="text-blue">{item.problemDesc}
                                                        <hr/>
                                                        {item.solution}</p>
                                                    <p
                                                        className={"text-end"}>{DateUtils.formatLocalWithTime(item.lastActionDate)}
                                                    </p>
                                                </div>
                                            </>
                                        )
                                    },
                                    footer: (item: any) => <div className={"d-flex justify-content-center gap-5"}>
                                        <CButton
                                            color="primary"
                                            onClick={
                                                () => {
                                                    setMyCrmUpdateRequestDTO(new MyCrmUpdateRequestDTO(item));
                                                    setIsUpdateModalVisible(true);
                                                }
                                            }
                                        >
                                            <FontAwesomeIcon icon={faPenToSquare}/>
                                        </CButton>
                                        <CButton
                                            className={"ms-3"}
                                            color="danger"
                                            onClick={
                                                () => {
                                                    setMyCrmIdToDelete(item.id);
                                                }
                                            }
                                        >
                                            <CIcon icon={cilTrash}/>
                                        </CButton>
                                    </div>,
                                }
                            }
                        />
                    </CCardBody>
                </CCard>
            </div>
            <MyCrmGeneratedModal isUpdateModalVisible={isUpdateModalVisible}
                                 setIsUpdateModalVisible={setIsUpdateModalVisible}
                                 getMyCrms={getMyCrms}
                                 t={t}
                                 showToast={showToast}
                                 myCrmUpdateRequestDTO={myCrmUpdateRequestDTO}
                                 setMyCrmUpdateRequestDTO={setMyCrmUpdateRequestDTO}
                                 myCrmIdToDelete={myCrmIdToDelete}
                                 setMyCrmIdToDelete={setMyCrmIdToDelete}
                                 partnerSubmissionId={-1}
            />
        </>
    )
}
