export class MyCrmUpdateRequestDTO {

    id: number;
    memberModelId: number;
    problemDesc: string;
    solution: string;
    priority: number;
    status: number;
    teamMemberId: number;
    partnerSubmissionId: number;

    constructor(data?: any) {
        this.id = data?.id ?? -1;
        this.memberModelId = data?.memberModelId ?? -1;
        this.problemDesc = data?.problemDesc ?? "";
        this.solution = data?.solution ?? "";
        this.teamMemberId = data?.teamMemberId ?? "";
        this.priority = data?.priority ?? -1;
        this.status = data?.status ?? 10;
        this.partnerSubmissionId = data?.partnerSubmissionId ?? -1;
    }
}