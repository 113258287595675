import {AxiosResponse} from "axios";
import {requestService} from "../Helpers/RequestService";
import {ApiRoutes} from "../../ApiRoutes";
import {LanguageView} from "../../Data/view/LanguageModel/LanguageView";
import {SelectOptionView} from "../../Data/view/SelectOption/SelectOptionView";

async function getLanguagesSupplier(): Promise<LanguageView[]> {

    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_LANGUAGE, {})
        .then((response) => {
            return response.data as LanguageView[];
        });
}

async function getLanguagesSupplierForSelect(): Promise<SelectOptionView[]> {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_LANGUAGE) {
        throw new Error("REACT_APP_URL_SUPPLIER_LANGUAGE is not defined");
    }

    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_LANGUAGE + "/french-dutch", {})
        .then((response) => {
            return response.data.map((language: LanguageView) => new SelectOptionView(language));
        });
}

async function getLanguagesAffiliate(): Promise<LanguageView[]> {


    return await requestService.get(ApiRoutes.REACT_APP_URL_AFFILIATE_LANGUAGE, {})
        .then((response) => {
            return response.data as LanguageView[];
        });
}

async function getLanguagesBuyer(): Promise<LanguageView[]> {

    return await requestService.get(ApiRoutes.REACT_APP_URL_BUYER_LANGUAGE, {})
        .then((response) => {
            return response.data as LanguageView[];
        });
}

async function getLanguagesForLogin(): Promise<LanguageView[]> {

    return await requestService.get(ApiRoutes.REACT_APP_LOGIN_LANGUAGE, {})
        .then((response) => {
            return response.data as LanguageView[];
        });
}

async function getLanguagesAffiliateForSelect(): Promise<SelectOptionView[]> {

    if (!ApiRoutes.REACT_APP_URL_AFFILIATE_LANGUAGE) {
        throw new Error("REACT_APP_URL_SUPPLIER_LANGUAGE is not defined");
    }

    return await requestService.get(ApiRoutes.REACT_APP_URL_AFFILIATE_LANGUAGE + "/french-dutch", {})
        .then((response) => {
            return response.data.map((language: LanguageView) => new SelectOptionView(language));
        });
}


export const LanguageService = {
    getLanguagesSupplier,
    getLanguagesAffiliate,
    getLanguagesAffiliateForSelect,
    getLanguagesSupplierForSelect,
    getLanguagesBuyer,
    getLanguagesForLogin
}