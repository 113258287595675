
export class ChangePasswordRequestDTO {

    loginId: string;
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    forgotPassword: boolean;
    passwordResetToken: string;
    teamID: number;

    constructor(data?: any) {
        this.loginId = data?.loginId ?? "";
        this.oldPassword = data?.oldPassword ?? "";
        this.newPassword = data?.newPassword ?? "";
        this.confirmPassword = data?.confirmPassword ?? "";
        this.forgotPassword = data?.forgotPassword ?? false;
        this.passwordResetToken = data?.passwordResetToken ?? "";
        this.teamID = data?.teamID ?? 0;
    }

}