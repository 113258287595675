import {requestService} from "../Helpers/RequestService";
import {ApiRoutes} from "../../ApiRoutes";
import {CreditNoteSearchRequestDTO} from "../../Data/request/CreditNoteSearchRequestDTO";
import {CreditNoteDTO} from "../../Data/CreditNoteDTO";
import {Page} from "../../Data/Page";
import {BbxRequest} from "../../Data/request/BbxRequest";

async function getAllCreditNotes( creditNoteSearchRequestDTO :BbxRequest<CreditNoteSearchRequestDTO>): Promise<Page<CreditNoteDTO>> {

    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_CREDIT_NOTES, creditNoteSearchRequestDTO)
        .then((response) => {
            return response.data as Page<CreditNoteDTO>;
        });
}

async function downloadCreditNotePDF(id : number): Promise<any> {

    let creditNoteDTO = new CreditNoteDTO();
    creditNoteDTO.id = id;

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_CREDIT_NOTES + `/download-credit-note-to-pdf`, creditNoteDTO, {
        responseType: 'blob',
    })
    return response;
}

async function getCreditNoteTemplate(id : number): Promise<string> {

    let creditNoteDTO = new CreditNoteDTO();
    creditNoteDTO.id = id;

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_CREDIT_NOTES + `/credit-note`, creditNoteDTO)
    return response.data as string;
}

async function getCreditNoteByUserIdAndInvoiceRef(id:number): Promise<CreditNoteDTO> {

    let creditNoteDTO = new CreditNoteDTO();
    creditNoteDTO.id = id;

    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_CREDIT_NOTES + `/credit-note-invoiceref`, creditNoteDTO)
        .then((response) => {
            return response.data as CreditNoteDTO;
        });
}

export const SupplierCreditNoteService = {
    getAllCreditNotes,
    downloadCreditNotePDF,
    getCreditNoteTemplate,
    getCreditNoteByUserIdAndInvoiceRef
}