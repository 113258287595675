//React and Coreui Imports
import {CNavGroup, CNavItem} from '@coreui/react';
import CIcon from '@coreui/icons-react';

//Types
import {NavGroup} from '../../../Shared/Interfaces/Navigation.interface';
import {cilColorBorder} from "@coreui/icons";

/**
 * Const Nav
 * Creates an array of objects that are used to create the sidebar navigation.
 * @NavGroup is an interface that defines the properties of the toggler (main categories in sidebar).
 * @NavItem is an interface that defines the properties of the navigation links (subcategories in sidebar).
 */
const buyerNav: NavGroup[] = [
    {
        name: 'Supplier.Nav.Activity',
        items: [
            {
                name: 'My projects',
                icon: CIcon,
                iconProps: {
                    icon: 'cilLibrary',
                    className: 'buyerNav-icon me-2',
                },
                to: '/buyer/projects/overview',
            },

            {
                name: 'New project',
                icon: CIcon,
                iconProps: {
                    icon: 'cilFile',
                    className: 'buyerNav-icon me-2'
                },
                to: '/buyer/projects/new-project',
            }
        ]
    },
    {
        name: 'My account',
        items: [
            {
                name: 'My profile',
                icon: CIcon,
                iconProps: {
                    icon: 'cilUser',
                    className: 'buyerNav-icon me-2'
                },
                to: '/buyer/account/profile',
            },
            {
                name: 'My password',
                icon: CIcon,
                iconProps: {
                    icon: 'cilLockLocked',
                    className: 'buyerNav-icon me-2'
                },
                to: '/buyer/account/password',
            },
            {
                name: 'My newsletters',
                icon: CIcon,
                iconProps: {
                    icon: 'cilInbox',
                    className: 'buyerNav-icon me-2'
                },
                to: '/buyer/account/newsletters',
            },

        ]
    },
    {
        name: 'Help',
        items: [
            {
                name: 'FAQ',
                icon: CIcon,
                iconProps: {
                    icon: 'cilCommentBubble',
                    className: 'buyerNav-icon me-2'
                },
                to: '/buyer/help/faq',
            },
            {
                name: 'Contact',
                icon: CIcon,
                iconProps: {
                    icon: 'cilContact',
                    className: 'buyerNav-icon me-2'
                },
                to: '/buyer/help/contact',
            }
        ]
    }
];

export default buyerNav;
