import {ApiRoutes} from "../../ApiRoutes";
import {requestService} from "../Helpers/RequestService";
import {Page} from "../../Data/Page";
import {BbxRequest} from "../../Data/request/BbxRequest";
import {MyCrmRequestDTO} from "../../Data/request/MyCrmRequestDTO";
import {SelectOptionView} from "../../Data/view/SelectOption/SelectOptionView";
import {MyCrmView} from "../../Data/view/MyCrmModel/MyCrmView";
import {MyCrmUpdateRequestDTO} from "../../Data/request/Update/MyCrmUpdateRequestDTO";

async function getMyCrms(request: BbxRequest<MyCrmRequestDTO>) {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_COMMENTS) {
        throw new Error("REACT_APP_URL_SUPPLIER_COMMENTS is not defined");
    }

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_COMMENTS, request);

    return new Page<MyCrmView>(response.data);
}

async function updateMyCrm(commentUpdateRequestDTO: MyCrmUpdateRequestDTO) {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_COMMENTS) {
        throw new Error("REACT_APP_URL_SUPPLIER_COMMENTS is not defined");
    }

    const response = await requestService.put(ApiRoutes.REACT_APP_URL_SUPPLIER_COMMENTS + "/update", commentUpdateRequestDTO);

    return response.data as number;
}


async function removeMyCrm(id: number) {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_COMMENTS) {
        throw new Error("REACT_APP_URL_SUPPLIER_COMMENTS is not defined");
    }

    const response = await requestService.del(ApiRoutes.REACT_APP_URL_SUPPLIER_COMMENTS + "/" + id + "/delete", {});

    return response.data as boolean;

}

export const MyCrmRequestService = {
    getMyCrms,
    updateMyCrm,
    removeMyCrm,
}