import React, {Suspense} from "react";
import {ApiRoutes} from "../../../ApiRoutes";
import {CContainer, CSpinner} from "@coreui/react";

export default function LanguagePage(): React.ReactElement {

    return (
        <div className="d-flex w-100">
            <CContainer className="bobex-app-content-wrapper">
                <CContainer className="bobex-app-content pt-md-0 p-md-4 p-2 mb-sm-0 mx-auto mx-sm-0">
                    <Suspense fallback={<CSpinner/>}>
                        <div className={"row mt-5"}>
                            <div className={"col-md-4 text-center"}>
                                <a className="btn btn-primary px-5 my-3" href={ApiRoutes.REACT_APP_BASE_URL_DEV_FRONTEND() + "/extranet-be/login"}>BE</a>
                            </div>
                            <div className={"col-md-4 text-center"}>
                                <a className="btn btn-primary px-5 my-3" href={ApiRoutes.REACT_APP_BASE_URL_DEV_FRONTEND() + "/extranet-nl/login"}>NL</a>
                            </div>
                            <div className={"col-md-4 text-center"}>
                                <a className="btn btn-primary px-5 my-3" href={ApiRoutes.REACT_APP_BASE_URL_DEV_FRONTEND() + "/extranet-ci/login"}>CI</a>
                            </div>
                        </div>
                    </Suspense>
                </CContainer>
            </CContainer>
        </div>
    )

}