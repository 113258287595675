import React, {useState} from "react";
import {accountService} from "../../../Services/Helpers/AccountService";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CForm,
    CFormInput,
    CFormLabel,
    CFormText,
    CInputGroup,
    CInputGroupText
} from "@coreui/react";

import logoBE from '../../../Assets/bobex-white-165.png';
import logoNL from '../../../Assets/bobexnl-white-165.png';
import logoCI from '../../../Assets/CI.png';
import logoVBK from '../../../Assets/verbouwkosten-logo.png';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {ChangePasswordRequestDTO} from "../../../Data/request/ChangePasswordRequest";
import {PasswordUtils} from "../../../utils/PasswordUtils";
import {useTranslation} from "react-i18next";
import {RouteUtils} from "../../../utils/RouteUtils";
import {BbxToastType} from "../../../CustomHooks/ToastCustomComponentProps/BbxToastType";
import {useTemplateUpdate} from "../../../Shared/Components/SidebarUpdateContextType/SidebarUpdateContextType";

export default function ForgotPassword() {

    const {showToast} = useTemplateUpdate();

    let [searchParams, setSearchParams] = useSearchParams();
    const [validated, setValidated] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [changePasswordRequestDTO, setChangePasswordRequestDTO] = useState<ChangePasswordRequestDTO>(new ChangePasswordRequestDTO());
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    function getLogo() {

        if (RouteUtils.getContext() === 'be') {
            return logoBE;
        } else if (RouteUtils.getContext() === 'nl') {
            return logoNL;
        } else if (RouteUtils.getContext() === 'ci') {
            return logoCI;
        } else if (RouteUtils.getContext() === 'vbk') {
            return logoVBK;
        }
        return logoBE;
    }
    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            showToast(BbxToastType.ERROR, t("Please fill in all the fields"));
            event.stopPropagation();
            return;
        }

        if (changePasswordRequestDTO.newPassword !== changePasswordRequestDTO.confirmPassword) {
            showToast(BbxToastType.ERROR, t("Passwords do not match"));
            event.stopPropagation();
            return;
        }

        // Check if the password is valid
        if (!isPasswordValid) {
            showToast(BbxToastType.ERROR, t("Password must be 8-20 characters long, contain letters, numbers and special characters"));
            event.stopPropagation();
            return;
        }

        setValidated(true);

        changePasswordRequestDTO.forgotPassword = true;
        changePasswordRequestDTO.passwordResetToken = searchParams.get('token') ?? "";

        accountService.changePassword( changePasswordRequestDTO).then((response) => {
            if (response.status === 200) {
                navigate("/login", { state: { showToast: true, toastType: BbxToastType.SUCCESS, toastMessage: t("Password successfully changed, you can now log in") } });
            } else {
                showToast(BbxToastType.ERROR, t("Error while changing password"));
            }
        }).catch((error) => {
            showToast(BbxToastType.ERROR, t(error.response.data.detail));
        });
    }

    const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangePasswordRequestDTO({
            ...changePasswordRequestDTO,
            newPassword: event.target.value.trim()
        })
        setIsPasswordValid(PasswordUtils.isPasswordValid(event.target.value));
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };



    return (
        <>
            <CInputGroup className="login-page vw-100 vh-100 d-flex justify-content-center align-items-center">
                <section className="position-absolute">
                    <div className='air air1'></div>
                    <div className='air air2'></div>
                    <div className='air air3'></div>
                    <div className='air air4'></div>
                </section>
                <CCard className="login-card">
                    <CCardHeader className="p-3">
                        <div className="d-flex justify-content-center">
                            <img src={getLogo()} alt="bobex-logo" width="125" height="auto"/>
                        </div>
                    </CCardHeader>
                    <CCardBody className="w-100 p-5">
                        <CForm
                            className=" password-reset-form m-auto d-flex flex-column"
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                            method={"post"}
                        >
                            <CFormLabel htmlFor="password-new">{t("Password")}</CFormLabel>
                            <CInputGroup className="mb-3">
                                <CInputGroupText onClick={togglePasswordVisibility}>
                                    {!showPassword ? (
                                        <FontAwesomeIcon icon={faEye}/>// Show password icon
                                    ) : (
                                        <FontAwesomeIcon icon={faEyeSlash}/> // Hide password icon
                                    )}
                                </CInputGroupText>
                                <CFormInput
                                    className="border-blue"
                                    type={showPassword ? 'text' : 'password'}
                                    name="newPassword"
                                    id="password-new"
                                    placeholder=""
                                    size="sm"
                                    required
                                    onChange={handlePassword}
                                    valid={isPasswordValid}
                                    invalid={!isPasswordValid && changePasswordRequestDTO.newPassword !== ""}
                                />
                            </CInputGroup>
                                <CFormText component="span" id="password-text" className="mb-2">
                                    {t('Password must be 8-20 characters long, contain letters, numbers and special characters')}
                                </CFormText>
                            <CFormLabel htmlFor="password-new-confirmation">{t('New password confirmation')}</CFormLabel>
                            <CInputGroup className="mb-3">
                                <CInputGroupText onClick={toggleConfirmPasswordVisibility}>
                                    {!showPassword ? (
                                        <FontAwesomeIcon icon={faEye}/>// Show password icon
                                    ) : (
                                        <FontAwesomeIcon icon={faEyeSlash}/> // Hide password icon
                                    )}
                                </CInputGroupText>
                                <CFormInput
                                    className="border-blue w-100"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    id="password-new-confirmation"
                                    size="sm"
                                    required
                                    valid={changePasswordRequestDTO.newPassword === changePasswordRequestDTO.confirmPassword && changePasswordRequestDTO.newPassword !== ""}
                                    invalid={changePasswordRequestDTO.newPassword !== changePasswordRequestDTO.confirmPassword && changePasswordRequestDTO.confirmPassword !== ""}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setChangePasswordRequestDTO({
                                            ...changePasswordRequestDTO,
                                            confirmPassword: event.target.value.trim()
                                        })
                                    }
                                    }
                                />
                            </CInputGroup>
                            <CButton type="submit" color={"info"} className="px-4 m-auto mt-4">{t("Reset password")}</CButton>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CInputGroup>
        </>
    )
}