export default class AuthenticationRequest {

    loginId: string;
    password: string;
    firstEntryLogin: boolean;
    firstEntryPassword: boolean;
    firstEntryTeamID: boolean;

    constructor(data?: any) {
        this.loginId = data?.loginId ?? "";
        this.password = data?.password ?? "";
        this.firstEntryLogin = data?.firstEntryLogin ?? true;
        this.firstEntryPassword = data?.firstEntryPassword ?? true;
        this.firstEntryTeamID = data?.firstEntryTeamID ?? true;

    }
}