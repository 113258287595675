import {ApiRoutes} from "../../ApiRoutes";
import {requestService} from "../Helpers/RequestService";
import {ExtranetFolderView} from "../../Data/view/ExtranetFolderModel/ExtranetFolderView";
import {FolderUpdateRequestDTO} from "../../Data/request/Update/FolderUpdateRequestDTO";
import {Page} from "../../Data/Page";
import {BbxRequest} from "../../Data/request/BbxRequest";
import {PartnerSubmissionRequestDTO} from "../../Data/request/PartnerSubmissionRequestDTO";
import {PartnerSubmissionView} from "../../Data/view/PartnerSubmissionModel/PartnerSubmissionView";
import {ExtranetFolderRequestDTO} from "../../Data/request/ExtranetFolderRequestDTO";
import {ExtranetJointFolderView} from "../../Data/view/ExtranetFolderModel/ExtranetJointFolderView";
import {SelectOptionView} from "../../Data/view/SelectOption/SelectOptionView";

async function getFolders(request: BbxRequest<Object>) {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER) {
        throw new Error("REACT_APP_URL_SUPPLIER_FOLDER is not defined");
    }

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER , request);

    return response.data as Page<ExtranetFolderView>;
}

async function getFolderById(folderId: number) {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER) {
        throw new Error("REACT_APP_URL_SUPPLIER_FOLDER is not defined");
    }

    const response = await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER + `/${folderId}`  , {});

    return response.data as ExtranetFolderView;
}

async function updateFolder(folderUpdateRequestDTO: FolderUpdateRequestDTO) {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER) {
        throw new Error("REACT_APP_URL_SUPPLIER_FOLDER is not defined");
    }

    const response = await requestService.put(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER + "/", folderUpdateRequestDTO);

    return response.data as number;
}

async function removeFolder(id: number) {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER) {
        throw new Error("REACT_APP_URL_SUPPLIER_FOLDER is not defined");
    }

    const response = await requestService.del(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER + "/" + id, {});

    return response.data as boolean;

}

async function getLeadsPerFolderId(request: BbxRequest<PartnerSubmissionRequestDTO>) {

    if (!ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER) {
        throw new Error("REACT_APP_URL_SUPPLIER_FOLDER is not defined");
    }

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER + "/" + request.data.folderId + "/leads", request);

    return response.data as Page<PartnerSubmissionView>;
}

/**
 * @deprecated
 */
async function getAllFolders(): Promise<SelectOptionView[]> {

    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER + "/folders", {})
        .then((response) => {
            return response.data as SelectOptionView[];
        });
}

async function getFolderPartnerSubmission(id:number): Promise<ExtranetJointFolderView> {

    let extranetFolderPartnerSubmissionRequestDTO = new ExtranetFolderRequestDTO();
    extranetFolderPartnerSubmissionRequestDTO.partnerSubmissionId = id;


    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER + "/partner-submission", extranetFolderPartnerSubmissionRequestDTO).then((response) => {
        return response.data as ExtranetJointFolderView;
    });
}

async function getExpertBusinessTypeFolders(id:number): Promise<ExtranetJointFolderView> {

    let extranetFolderPartnerSubmissionRequestDTO = new ExtranetFolderRequestDTO();
    extranetFolderPartnerSubmissionRequestDTO.expertBusinessTypeId = id;


    return await requestService.get(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER + "/expert-business-type", extranetFolderPartnerSubmissionRequestDTO).then((response) => {
        return response.data as ExtranetJointFolderView;
    });
}

async function updateExtranetFolder(extranetFolderPartnerSubmissionRequestDTO:ExtranetFolderRequestDTO): Promise<boolean> {

    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_FOLDER + "/update-extranet-folder", extranetFolderPartnerSubmissionRequestDTO).then((response) => {
        return response.data as boolean;
    });
}

export const FolderRequestService = {
    getFolders,
    updateFolder,
    removeFolder,
    getLeadsPerFolderId,
    getFolderById,
    getAllFolders,
    getFolderPartnerSubmission,
    getExpertBusinessTypeFolders,
    updateExtranetFolder
}