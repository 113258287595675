//Used for props type in SupplierContact.tsx
import {CountryView} from "../CountryModel/CountryView";

export class CompanyView {

    id: number;
    name: string;
    address1: string;
    address2: string;
    zipCode: string;
    country: CountryView
    regionName: string;
    phone: string;
    mobil: string;
    website: string;
    tva: string;
    logo: string;
    city: string;
    companyType: string;


    constructor(data?: any) {
        this.id = data?.id ?? 0
        this.name = data?.name ?? ""
        this.address1 = data?.address1 ?? ""
        this.address2 = data?.address2 ?? ""
        this.zipCode = data?.zipCode ?? ""
        this.city = data?.city ?? ""
        this.country = data?.country ?? new CountryView()
        this.regionName = data?.regionName ?? ""
        this.phone = data?.phone ?? ""
        this.mobil = data?.mobil ?? ""
        this.website = data?.website ?? ""
        this.tva = data?.tva ?? ""
        this.logo = data?.logo ?? ""
        this.city = data?.city ?? ""
        this.companyType = data?.companyType ?? ""
    }


}