function getNestedObjectFromPath(obj: any, path: string): any {
    if (path === undefined) return "";
    if (path === "") return "";
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

function setNestedObjectFromUrlParams(obj: any, urlParams: URLSearchParams): any {
    urlParams.forEach((value, key) => {
        return setNestedObjectFromPath(obj, key,value)
    });

    return obj;
}

function setNestedObjectFromPath(obj: any, path: string,value:String): any {
    if (path.includes(".")) {
        return setNestedObjectFromPath(obj[path.split(".")[0]], path.substring(path.indexOf('.') + 1),value)
    } else {
        obj[path] = value;
        return obj;
    }
}

export const ClassesUtils = {
    getNestedObjectFromPath,
    setNestedObjectFromUrlParams
}