//React and Coreui Imports
import React from 'react';
import {Route} from "react-router-dom";
import ProtectedRoute from "../../../Shared/Components/ProtectedRoute/ProtectedRoute";

//Types
import {Routes} from "../../../Shared/Interfaces/Routes.interface";

//Components
const Projects = React.lazy(() => import('../Projects/ProjectsOverview/ProjectsOverview'));
const DetailedProject = React.lazy(() => import('../Projects/ProjectDetailedOverview/ProjectDetailedOverview'));
const SuppliersRating = React.lazy(() => import('../SuppliersRating/SuppliersRatingOverview/SuppliersRatingOverview'));
const NewRequest = React.lazy(() => import('../NewRequest/NewRequest'));
const Profile = React.lazy(() => import('../Profile/Profile'));
const Password = React.lazy(() => import('../Password/Password'));
const Newsletters = React.lazy(() => import('../Newsletters/Newsletters'));
const Privacy = React.lazy(() => import('../Privacy/Privacy'));
const FAQ = React.lazy(() => import('../../../Shared/Components/FAQ/FAQ'));
const Contact = React.lazy(() => import('../Contact/Contact'));
const NotFound = React.lazy(() => import('../../../Shared/Components/PageNotFound/PageNotFound'));
const NoAccess = React.lazy(() => import("../../../Shared/Components/NoAccessToPage/NoAccessToPage"));
const Feedback = React.lazy(() => import('../Feedback/Feedback'));


/**
 * BobexRoutesDetails
 * Array of objects containing the routes of the application and their linked components.
 */
export const BuyerRoutes: Routes[] = [
    {path: 'projects/overview', name: 'Overview', element: Projects},
    {path: 'projects/overview/:id', name: 'Project details', element: DetailedProject},
    {path: 'projects/overview/:id/review', name: 'My suppliers', element: SuppliersRating},
    {path: 'projects/new-project', name: 'New project', element: NewRequest},
    {path: 'account/profile', name: 'My profile', element: Profile},
    {path: 'account/password', name: 'My password', element: Password},
    {path: 'account/newsletters', name: 'My newsletters', element: Newsletters},
    {path: 'account/privacy', name: 'Privacy', element: Privacy},
    {path: 'account/feedback', name: 'Feedback', element: Feedback},
    {path: 'help/faq', name: 'Frequently Asked Questions', element: FAQ},
    {path: 'help/contact', name: 'Contact', element: Contact},
    {path: 'no-access', name: '', element: NoAccess},
    {path: '*', name: '', element: NotFound},
];

/**
 * BobexRoutes
 * Creates a Route component for each route in BobexRoutesDetails.
 * Used to import all routes in the application.
 */
export const BobexBuyerRoutes = BuyerRoutes.map((route, index) => {
    return (
        route.element &&
        <Route key={index} element={<ProtectedRoute />}>
            <Route path={route.path} element={(<route.element/>)}/>
        </Route>
    )
});





