/*
 * When using this component, you need to pass the status and the cases array. Pay attention to the order of the cases array.
 * The first element of the array is the success case, the second is the warning case and the third is the danger case.
 */

import {CBadge, CTooltip} from "@coreui/react";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScaleBalanced} from "@fortawesome/free-solid-svg-icons";

export const getBadgeColor = (status: any, cases: (string | number)[]) => {
    switch (status) {
        case cases[0]:
            return 'success'
        case cases[1]:
            return 'warning'
        case cases[2]:
            return 'danger'
        default:
            return 'primary'
    }
}

export const getBadgeContent = (status: any, cases: (string | number)[]) => {
    switch (status) {
        case cases[0]:
            return "✓"
        case cases[1]:
            return "?"
        case cases[2]:
            return "✗"
        default:
            return '-'
    }
}

export const getSubmissionStatusBadge = (t: Function, status: any) => {


    switch (status) {
        case 13:

            return (
                <CTooltip content={t("Lead received")}>
                    <p className={"text-white"}>
                        <CBadge color={"success"}>✓</CBadge>
                    </p>
                </CTooltip>
            )

        case 48:
            return (
                <CTooltip content={t("Your protest is being processed")}>

                    <CBadge color={"secondary"}>
                        <p className={"text-white"}>
                            <FontAwesomeIcon className={"me-2"} icon={faScaleBalanced}/>
                            ?
                        </p>
                    </CBadge>

                </CTooltip>
            )
        case 43:
            return (
                <CTooltip content={t("Your protest has been rejected")}>
                    <p className={"text-white"}>
                        <CBadge color={"warning"}>
                            <FontAwesomeIcon className={"me-2"} icon={faScaleBalanced}/>
                            ✓
                        </CBadge>
                    </p>
                </CTooltip>
            )
        case 58:
            return (
                <CTooltip content={t("Your protest has been accepted")}>
                    <p className={"text-white"}>
                        <CBadge color={"danger"}>
                            <FontAwesomeIcon className={"me-2"} icon={faScaleBalanced}/>
                            x
                        </CBadge>
                    </p>
                </CTooltip>
            )
    }
}