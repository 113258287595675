import React from 'react';
import {
    CToast,
    CToastHeader,
    CToastBody,
    CToastClose, CToaster,
} from '@coreui/react';
import {BbxToastType} from "./BbxToastType";
import {useTranslation} from "react-i18next";

interface BbxToastProps {
    autohide?: boolean;
    visible?: boolean;
    onClose?: () => void;
    title: string;
    body: string;
    type: BbxToastType;
}

export const BbxToast: React.FC<BbxToastProps> = ({
                                                      autohide = true,
                                                      visible = false,
                                                      onClose,
                                                      title,
                                                      body,
                                                      type
                                                  }) => {

    const color = type === 'Success' ? 'var(--cui-success)' : '#E53935';
    const {t} = useTranslation();

    return (
        <CToaster placement="top-end">
            <CToast autohide={autohide} visible={visible} onClose={onClose}>
                <CToastHeader>
                    <svg
                        className="rounded me-2"
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                        role="img"
                    >
                        <rect width="100%" height="100%" fill={color}></rect>
                    </svg>
                    <div className="fw-bold me-auto">{t(title)}</div>
                    <CToastClose onClick={onClose} className="me-2 m-auto"/>
                </CToastHeader>
                <CToastBody>{body}</CToastBody>
            </CToast>
        </CToaster>
    );
};
