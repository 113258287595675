export class BbxRequest<T> {
    page: number;
    size: number;
    sortColumn: string;
    sortDirection: string
    data: T;
    searchForm: T;
    initialState: T;

    constructor(DataType: new (data?: any) => T, data?: any) {
        this.page = data?.page ?? 0;
        this.size = data?.size ?? 10;
        this.sortColumn = data?.sortColumn ?? "id";
        this.sortDirection = data?.sortDirection ?? "desc";
        this.data = data?.data ? new DataType(data?.data) : new DataType(); //what is sent to the api
        this.searchForm = data?.data ? new DataType(data?.data) : new DataType(); //what is controlling the form state
        this.initialState = data?.data ? new DataType(data?.data) : new DataType(); // initial state
    }


}