import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//locales
import en_US from "./Languages/en_US.json";
import fr_BE from "./Languages/fr_BE.json";
import nl_BE from "./Languages/nl_BE.json";
import nl_NL from "./Languages/nl_NL.json";
import {accountService} from "./Services/Helpers/AccountService";
import {LanguageUtils} from "./utils/LanguageUtils";

const resources = {
    en_US: en_US,
    fr_BE: fr_BE,
    nl_BE: nl_BE,
    nl_NL: nl_NL,
};

const userLanguage = LanguageUtils.getLocale();


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: false,
        resources,
        lng: userLanguage, // default choose operator language
        // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        fallbackLng: "en_US", // fallback language
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;