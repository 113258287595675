import React, {useEffect} from 'react';

//React and Coreui Imports
import {
    CButton,
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormInput,
    CHeader,
    CHeaderNav,
    CHeaderToggler,
    CNav,
    CNavItem, CNavLink
} from '@coreui/react';
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faSearch, faUser} from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';

//Stylesheets
import './Header.css';

//Context
import {useTranslation} from "react-i18next";
import {accountService} from "../../../Services/Helpers/AccountService";

//Assets
import LanguageSwitcher from "./LanguageSwitcher";
import SupplierSearchHeader from "../../../Components/Supplier/Header/SupplierSearchHeader";
import useMediaWidth from "../../../CustomHooks/UseMediaWidth";
import {RouteUtils} from "../../../utils/RouteUtils";
import CIcon from "@coreui/icons-react";
import {FileUtils} from "../../../utils/FileUtils";
import {useTemplateUpdate} from "../SidebarUpdateContextType/SidebarUpdateContextType";
import AffiliateSearchHeader from "../../../Components/Affiliate/Header/AffiliateSearchHeader";
import BuyerSearchHeader from "../../../Components/Buyer/Header/BuyerSearchHeader";
import {DateUtils} from "../../../utils/DateUtils";
import {LanguageUtils} from "../../../utils/LanguageUtils";


/**
 * Header component
 * Creates fixed header bar. Contains toggle button, search bar and user menu.
 * Depends on sidebarVisibilityContext.
 * @return {React.ReactElement}
 */
export default function Header(): React.ReactElement {
    const {t} = useTranslation();
    const truc = useTemplateUpdate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isResponsive = useMediaWidth(576);
    const user = accountService.getUser();
    let baseURL = "/extranet-" + RouteUtils.getContext();
    let sidebarShow = useSelector((state: any) => state.SidebarReducer.sidebarShow);
    const [userAdmin, setUserAdmin] = React.useState(false);
    const [randomId, setRandomId] = React.useState(0);

    const signout = () => {
        accountService.logout();
        navigate(baseURL + "/login");
    }

    useEffect(() => {
        isUserAdmin();
    }, [user]);


    useEffect(() => {
        setRandomId(Math.random());
    }, [truc.triggerHeaderRefresh]);

    function isUserAdmin() {
        if (!user.isTeamMember) {
            setUserAdmin(true);
        } else if (user.isTeamMember && user.teamMemberRole === 1) {
            setUserAdmin(true);
        } else {
            setUserAdmin(false);
        }
    }

    return (
        <>
            {isResponsive &&
                <CHeader className="">
                    <div className={"d-flex justify-content-between w-100"}>
                        <CHeaderNav className="d-flex align-items-center">
                            <CNavItem className="">
                                <CHeaderToggler
                                    onClick={() => {
                                        dispatch({type: 'set', sidebarShow: !sidebarShow})
                                    }}>
                                    <div className="icon-wrapper">
                                        <FontAwesomeIcon icon={faBars} color="#10375e"/>
                                    </div>
                                </CHeaderToggler>
                            </CNavItem>
                            <CNavItem className="">
                                {user.authority === "supplier" && <SupplierSearchHeader/>}
                                {user.authority === "buyer" && <BuyerSearchHeader/>}
                                {user.authority === "affiliate" && <AffiliateSearchHeader/>}
                            </CNavItem>
                        </CHeaderNav>
                        <CHeaderNav className="d-flex align-items-center">

                            {/*  <CNavItem className="me-3">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faBell} color="#10375e" size="xl"/>
                                </div>
                            </CNavItem>*/}

                            {user.authority && (
                                <>
                                    <p className={"text-white me-5"}>{DateUtils.formatHeaderDate(LanguageUtils.getLocale())}</p>
                                    <CNavItem className="me-3 d-flex flex-column align-items-end">
                                        <p style={{color: "var(--icons-grey)"}}>{t('Hello')} {user.firstname} {user.lastname}</p>
                                        {user.authority === "supplier" || user.authority === "affiliate" ? (
                                            <p style={{color: "var(--icon-blue)"}}>{user.companyName}</p>
                                        ) : null}
                                    </CNavItem>
                                </>
                            )}

                            <CNavItem className="me-3">
                                <CDropdown className="header-nav-icon" direction="center">
                                    <CDropdownToggle className="icon-wrapper">
                                        {user.authority === "supplier" ?
                                            <img style={{maxHeight: "100%", maxWidth: "100%", cursor: "pointer"}}
                                                 src={FileUtils.formatDisplayLogo(user.companyPictureUrl) + "?ver=" + randomId}
                                                 alt={user.companyName} className="rounded-circle"/> :
                                            <FontAwesomeIcon icon={faUser} color="#10375e" size="xl"/>}
                                    </CDropdownToggle>
                                    <CDropdownMenu className="mt-3">

                                        {user.authority === "supplier" && <>
                                            {userAdmin && <>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center'}
                                                    onClick={(e) => navigate('activity/categories-and-work-areas')}
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                       <CIcon icon={"cilMap"} className={"me-2"}/>
                                                    </div>
                                                    {t("Categories and regions")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center'}
                                                    onClick={(e) => navigate("/supplier/account/invoices")}
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                        <CIcon icon={"cilMoney"} className=" me-2"></CIcon>
                                                    </div>
                                                    {t("Invoices")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center'}
                                                    onClick={(e) => navigate("/supplier/account/team-members")}
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                        <CIcon icon={"cilPeople"} className="me-2"></CIcon>
                                                    </div>
                                                    {t("My team")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center'}
                                                    onClick={(e) => navigate("/supplier/account/contact-details")}
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                        <i className="fal fa-user me-2"></i>
                                                    </div>
                                                    {t("My contact details")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center'}
                                                    onClick={(e) => navigate("/supplier/account/profile")}
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                        <CIcon icon={"cilSettings"} className={"me-2"}/>
                                                    </div>
                                                    {t("Profile")}
                                                </CDropdownItem>
                                            </>
                                            }
                                            <CDropdownItem
                                                component="button"
                                                className={'d-flex align-items-center'}
                                                onClick={(e) => navigate("/supplier/account/password")}
                                            >
                                                <div style={{minWidth: "30px"}}>
                                                    <i className="fal fa-lock-alt me-2"></i>
                                                </div>
                                                {t("My password")}
                                            </CDropdownItem>
                                            <CDropdownItem
                                                component="a"
                                                className={'d-flex align-items-center'}
                                                href={LanguageUtils.getPrivacyPageByLanguage(user.locale, user.context)}
                                                target="_blank" // This will open the link in a new tab
                                            >
                                                <div style={{minWidth: "30px"}}>
                                                    <i className="fal fa-user-secret"></i>
                                                </div>
                                                {t("Privacy")}
                                            </CDropdownItem>
                                            <CDropdownItem
                                                component="button"
                                                className={'d-flex align-items-center'}
                                                onClick={(e) => navigate("/supplier/help")}
                                            >
                                                <div style={{minWidth: "30px"}}>
                                                    <i className="fal fa-question-circle me-2"></i>
                                                </div>
                                                {t("Help")}
                                            </CDropdownItem>
                                        </>}
                                        {user.authority === "buyer" &&
                                            <>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center'}
                                                    onClick={(e) => navigate("/buyer/account/profile")}
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                        <i className="fal fa-user"></i>
                                                    </div>
                                                    {t("My profile")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center'}
                                                    onClick={(e) => navigate("/buyer/account/password")}
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                        <i className="fal fa-lock-alt"></i>
                                                    </div>
                                                    {t("My password")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center'}
                                                    onClick={(e) => navigate("/buyer/account/newsletters")}
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                        <i className="fal fa-bell"></i>
                                                    </div>
                                                    {t("Newsletters")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="a"
                                                    className={'d-flex align-items-center'}
                                                    href={LanguageUtils.getPrivacyPageByLanguage(user.locale, user.context)}
                                                    target="_blank" // This will open the link in a new tab
                                                >
                                                    <div style={{minWidth: "30px"}}>
                                                        <i className="fal fa-user-secret"></i>
                                                    </div>
                                                    {t("Privacy")}
                                                </CDropdownItem>
                                            </>
                                        }
                                        {user.authority === "affiliate" && <>
                                            <CDropdownItem
                                                component="a"
                                                className={'d-flex align-items-center'}
                                                href={LanguageUtils.getPrivacyPageByLanguage(user.locale, user.context)}
                                                target="_blank" // This will open the link in a new tab
                                            >
                                                <i className="fal fa-user-secret"></i>
                                                {t("Privacy")}
                                            </CDropdownItem>
                                        </>
                                        }
                                        <CDropdownItem
                                            component="button"
                                            className={'d-flex align-items-center'}
                                            onClick={signout}
                                        >
                                            <div style={{minWidth: "30px"}}>
                                                <i className="fal fa-sign-out-alt me-2"></i>
                                            </div>
                                            {t("Logout")}
                                        </CDropdownItem>
                                    </CDropdownMenu>
                                </CDropdown>
                            </CNavItem>
                            <CNavItem className="">
                                <LanguageSwitcher/>
                            </CNavItem>
                        </CHeaderNav>
                    </div>
                </CHeader>
            }
            {!isResponsive &&
                <CHeader>
                    <CNav className={"responsive-header d-flex flex-row justify-content-around"}>
                        <CNavItem>
                            <CHeaderToggler
                                className="icon-wrapper responsive-toggler"
                                onClick={() => {
                                    dispatch({type: 'set', sidebarShow: !sidebarShow})
                                }}
                            >
                                <i className="fal fa-bars"></i>
                            </CHeaderToggler>
                        </CNavItem>
                        <CNavItem className="me-3">
                            {user.authority === "affiliate" ?
                                <CDropdown className="header-nav-icon" direction="dropup" placement="bottom">
                                    <CDropdownToggle className="icon-wrapper">
                                        <FontAwesomeIcon icon={faUser} color="#10375e" size="xl"/>
                                    </CDropdownToggle>
                                    <CDropdownMenu className="mb-3 p-3">
                                        <CDropdownItem
                                            component="a"
                                            className={'d-flex align-items-center'}
                                            href={LanguageUtils.getPrivacyPageByLanguage(user.locale, user.context)}
                                            target="_blank" // This will open the link in a new tab
                                        >
                                            <i className="fal fa-user-secret"></i>
                                            {t("Privacy")}
                                        </CDropdownItem>
                                    </CDropdownMenu>
                                </CDropdown>
                                :
                                <CDropdown className="header-nav-icon" direction="dropup" placement="bottom">
                                    <CDropdownToggle className="icon-wrapper">
                                        {user.authority === "supplier" ?
                                            <img
                                                style={{maxHeight: "100%", maxWidth: "100%", cursor: "pointer"}}
                                                src={FileUtils.formatDisplayLogo(user.companyPictureUrl) + "?ver=" + randomId}
                                                alt={user.companyName} className="rounded-circle"/> :
                                            <FontAwesomeIcon icon={faUser} color="#10375e" size="xl"/>
                                        }
                                    </CDropdownToggle>
                                    <CDropdownMenu className="mb-3 p-3">
                                        {user.authority === "supplier" && <>
                                            <CDropdownItem
                                                component="button"
                                                className={'d-flex align-items-center mt-3'}
                                                onClick={(e) => navigate("/supplier/account/contact-details")}
                                            >
                                                <i className="fal fa-user"></i>
                                                {t("My contact details")}
                                            </CDropdownItem>
                                            <CDropdownItem
                                                component="button"
                                                className={'d-flex align-items-center mt-3'}
                                                onClick={(e) => navigate("/supplier/account/password")}
                                            >
                                                <i className="fal fa-lock-alt"></i>
                                                {t("My password")}
                                            </CDropdownItem>
                                            <CDropdownItem
                                                component="button"
                                                className={'d-flex align-items-center mt-3'}
                                                onClick={(e) => navigate("/supplier/account/team-members")}
                                            >
                                                <i className="fal fa-users"></i>
                                                {t("My team")}
                                            </CDropdownItem>
                                            <CDropdownItem
                                                component="button"
                                                className={'d-flex align-items-center mt-3'}
                                                onClick={(e) => navigate('activity/categories-and-work-areas')}
                                            >
                                                <i className="fal fa-folder"></i>
                                                {t("Categories and regions")}
                                            </CDropdownItem>
                                            <CDropdownItem
                                                component="button"
                                                className={'d-flex align-items-center mt-3'}
                                                onClick={(e) => navigate("/supplier/account/profile")}
                                            >
                                                <CIcon icon={"cilSettings"} className={"me-2"}/>
                                                {t("Profile")}
                                            </CDropdownItem>
                                            <CDropdownItem
                                                component="a"
                                                className={'d-flex align-items-center mt-3'}
                                                href={LanguageUtils.getPrivacyPageByLanguage(user.locale, user.context)}
                                                target="_blank" // This will open the link in a new tab
                                            >
                                                <i className="fal fa-user-secret"></i>
                                                {t("Privacy")}
                                            </CDropdownItem>
                                            <CDropdownItem
                                                component="button"
                                                className={'d-flex align-items-center mt-3'}
                                                onClick={(e) => navigate("/supplier/help")}
                                            >
                                                <i className="fal fa-question-circle"></i>
                                                {t("Help")}
                                            </CDropdownItem>
                                        </>}
                                        {user.authority === "buyer" &&
                                            <>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center mt-3'}
                                                    onClick={(e) => navigate("/buyer/account/profile")}
                                                >
                                                    <i className="fal fa-user"></i>
                                                    {t("My contact details")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center mt-3'}
                                                    onClick={(e) => navigate("/buyer/account/password")}
                                                >
                                                    <i className="fal fa-lock-alt"></i>
                                                    {t("My password")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="button"
                                                    className={'d-flex align-items-center mt-3'}
                                                    onClick={(e) => navigate("/buyer/account/newsletters")}
                                                >
                                                    <i className="fal fa-bell"></i>
                                                    {t("Newsletters")}
                                                </CDropdownItem>
                                                <CDropdownItem
                                                    component="a"
                                                    className={'d-flex align-items-center mt-3'}
                                                    href={LanguageUtils.getPrivacyPageByLanguage(user.locale, user.context)}
                                                    target="_blank" // This will open the link in a new tab
                                                >
                                                    <i className="fal fa-user-secret"></i>
                                                    {t("Privacy")}
                                                </CDropdownItem>
                                            </>
                                        }
                                    </CDropdownMenu>
                                </CDropdown>
                            }
                        </CNavItem>
                        <CNavItem>
                            <CButton className="icon-wrapper" onClick={signout}>
                                <i className="fal fa-sign-out-alt"></i>
                            </CButton>
                        </CNavItem>
                    </CNav>
                </CHeader>
            }
        </>
    )
}