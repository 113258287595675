//React and Coreui Imports
import React from 'react';
import {CButton, CCard, CCardBody, CCardText, CCardTitle} from "@coreui/react";
import {useTranslation} from "react-i18next";

/**
 * ErrorHandler Component. This component is shown when a problem occurred in the app and launched error boundary component.
 * @return {React.ReactElement}
 */
export default function ErrorHandler({ error , resetErrorBoundary }: any): React.ReactElement {
    const {t} = useTranslation();

    return (
        <div className="d-flex justify-content-center">
            <CCard className="text-center mt-5">
                <CCardBody>
                    <CCardTitle>{t("Something went wrong")}</CCardTitle>
                    <CCardText>
                        {t("Try clicking the refresh page button to reload the application.")}
                    </CCardText>
                    <CButton color={"info"} className = "mt-3" onClick={resetErrorBoundary}>{t("Refresh")}</CButton>
                </CCardBody>
            </CCard>
        </div>
    )
}
