import React from 'react';

//React and Coreui Imports
import {NavLink, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

//Types
import {NavGroup} from '../../Interfaces/Navigation.interface';
import {accountService} from "../../../Services/Helpers/AccountService";
import {CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CNavItem, CNavTitle} from "@coreui/react";


/**
 * Sidebar Navigation Component
 * Used to create the navigation items in the sidebar.
 * @returns {React.ReactElement}
 */
export default function SidebarNavigation({items}: {
    items: NavGroup[],
}): React.ReactElement {
    const location = useLocation();
    const {t} = useTranslation();
    const user = accountService.getUser();
    let completeUrl = location.pathname + location.search;

    const activeItemKey = items.findIndex(item =>
            item && item.items.some(navItem =>
                location.pathname.includes(navItem.to) &&
                !(user.isTeamMember && navItem.teamMemberAllowed === false)
            )
    );

    return (
        <>
            <CAccordion
                className={"sidebar-nav"}
                activeItemKey={activeItemKey === -1 ? 0 : activeItemKey}
            >

                {items &&
                    items.map((item, index) => {
                        if (item === null) {
                            return;
                        }

                        if (item.teamMemberAllowed === false && user.isTeamMember) {
                            if (user.teamMemberRole === 3){
                                return null;
                            }
                        }

                        if (item.type !== undefined && item.type === "sidebarHeader") {
                            return (
                                <div  key={index}>
                                    <CNavTitle
                                        key={index}
                                        className={item.className}
                                    >
                                        {t(item.name)}
                                    </CNavTitle>
                                    <hr className={"m-0"}/>
                                </div>
                            );
                        }

                        return (
                            <CAccordionItem
                                itemKey={index}
                                key={index}

                            >
                                <CAccordionHeader>{t(item.name)}</CAccordionHeader>
                                <CAccordionBody>
                                    {item?.items.map((navItem, index) => {

                                        if (user.isTeamMember && navItem.teamMemberAllowed === false) {
                                            if (user.teamMemberRole === 3){
                                                return null;
                                            }
                                        }

                                        return (
                                            <CNavItem
                                                key={index}
                                                active={location.pathname.includes(navItem.to)}

                                            >
                                                <NavLink
                                                    to={navItem.to}
                                                    className={(navItem.to).includes(completeUrl) ? 'nav-link active-item' : 'nav-link'}
                                                >
                                                    <navItem.icon {...navItem.iconProps}/>
                                                    <>
                                                        {t(navItem.name)}
                                                        {navItem.badge && navItem.badge}
                                                    </>
                                                </NavLink>
                                            </CNavItem>)

                                    })}
                                </CAccordionBody>
                            </CAccordionItem>
                        );
                    })}
            </CAccordion>
        </>
    );
}