import {requestService} from "../Helpers/RequestService";
import {ApiRoutes} from "../../ApiRoutes";
import {InvoiceAcctRequestDTO} from "../../Data/request/InvoiceAcctRequestDTO";
import {PageRequestDTO} from "../../Data/request/PageRequestDTO";
import {BbxRequest} from "../../Data/request/BbxRequest";
import {Page} from "../../Data/Page";
import {InvoiceAcctDTO} from "../../Data/InvoiceAcctDTO";

async function getInvoiceTemplateByUserIdAndInvoiceId(id : number): Promise<string> {

    let invoiceAcctRequestDTO = new InvoiceAcctRequestDTO()
    invoiceAcctRequestDTO.id = id;
    invoiceAcctRequestDTO.details=true;

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_INVOICES + `/invoice`, invoiceAcctRequestDTO)
    return response.data as string;
}

async function downloadInvoicePdf(id : number, details:boolean): Promise<any> {

    let invoiceAcctRequestDTO = new InvoiceAcctRequestDTO()
    invoiceAcctRequestDTO.id = id;
    invoiceAcctRequestDTO.details = details;

    const response = await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_INVOICES + `/downloadInvoicePdf`, invoiceAcctRequestDTO, {
        responseType: 'blob',
    })
    return response;
}

async function getExpertInvoices(pageRequestDTO: BbxRequest<PageRequestDTO>): Promise<Page<InvoiceAcctDTO>> {
    return await requestService.post(ApiRoutes.REACT_APP_URL_SUPPLIER_INVOICES, pageRequestDTO)
        .then((response) => {
            return response.data as Page<InvoiceAcctDTO>;
        });
}

export const SupplierInvoiceAcctService = {
    getInvoiceTemplateByUserIdAndInvoiceId,
    downloadInvoicePdf,
    getExpertInvoices
}
