

export class BannerAffiliateRequestDTO {
    id: number;
    languageID: number;
    businessTypeIDs: number[];

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.languageID = data?.languageID ?? 0;
        this.businessTypeIDs = data?.businessTypeIDs ?? [];
    }
}