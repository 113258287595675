import React, {ReactNode} from 'react';
import {RouteUtils} from "../utils/RouteUtils";


/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const ThemeBE = React.lazy(() => import('./ThemeBE'));
const ThemeNL = React.lazy(() => import('./ThemeNL'));
const ThemeCI = React.lazy(() => import('./ThemeCI'));
const ThemeVBK = React.lazy(() => import('./ThemeVBK'));
const ThemeDark = React.lazy(() => import('./ThemeDark'));

type ThemeSelectorProps = {
    children: ReactNode;
};

const ThemeSelector: React.FC<ThemeSelectorProps> = ({children}) => (
    <>
        {/* Conditionally render theme, based on the current client context */}
        <React.Suspense fallback={<div>Loading...</div>}>
            {RouteUtils.getContext() === "be" ? <ThemeBE children={children}/> : null}
            {RouteUtils.getContext() === "nl" ? <ThemeNL children={children}/> : null}
            {RouteUtils.getContext() === "ci" ? <ThemeCI children={children}/> : null}
            {RouteUtils.getContext() === "vbk" ? <ThemeVBK children={children}/> : null}
            {/*{RouteUtils.getContext() === "dark" ? <ThemeDark/> : null}*/}
        </React.Suspense>
    </>
);

export default ThemeSelector;