export class CountryView {
    id: number;
    name: string;
    code: string;

    constructor(data?: any) {
        this.id = data?.id ?? 0
        this.name = data?.name ?? ""
        this.code = data?.code ?? ""
    }
}