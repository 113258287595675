//React and Coreui imports
import React from "react";

//Stylesheets
import './Loader.css'

/**
 * Loader component. Creates a loading animation.
 * @return React.ReactElement
 */
export default function Loader({className}: { className?: string }): React.ReactElement {
    return (
        <div className={"d-flex h-100 w-100 " + className ?? ""}>
            <div className="lds-ring m-auto">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}