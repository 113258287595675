export class TeamLeaderRequestDTO {
    deal: boolean;
    state?: string | null;
    code?: string | null;

    constructor(data?: any) {
        this.deal = data?.deal ?? false;
        this.state = data?.state ?? '';
        this.code = data?.code ?? '';
    }
}